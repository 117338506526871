import type { Either } from "fp-ts/Either";
import * as t from "io-ts";
import type { Opaque } from "type-fest";

export type TrimmedString = Opaque<string, "TrimmedString">;

/**
 * A type predicate which returns `true` if `input` is a `TrimmedString`.
 *
 * Exposed as `trimmedStringCodec.is()`.
 */
function is(input: unknown): input is TrimmedString {
  return typeof input === "string" && input === input.trim();
}

/**
 * Returns either a success object, with a trimmed string, or failure object.
 *
 * Exposed as `trimmedStringCodec.decode()`.
 */
function validate(
  input: unknown,
  context: t.Context,
): Either<t.Errors, TrimmedString> {
  return typeof input === "string"
    ? t.success(input.trim() as TrimmedString)
    : t.failure(input, context);
}

/**
 * Returns the `TrimmedString` as a `string`.
 *
 * Exposed as `trimmedStringCodec.encode()`.
 */
function encode(input: TrimmedString): string {
  return input;
}

/**
 * Validates that the input is a string and returns it trimmed.
 */
export const trimmedStringCodec = new t.Type(
  "trimmedString",
  is,
  validate,
  encode,
);
