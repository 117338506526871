/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPreparedPayload,
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
} from "@reduxjs/toolkit";

import type { SessionRequestState } from "./SessionRequestState";
import type { SessionState } from "./sessionSlice";

/**
 * A generic constraint on the `SessionState` type which limits keys to those
 * corresponding to request states.
 */
type NameConstraint = keyof {
  [P in keyof SessionState as SessionState[P] extends SessionRequestState<any>
    ? P
    : never]: unknown;
};

/**
 * Computes the `singleRequest` option bases on whether or not the request type
 * has a `currentRequestId` field, which is used to track if there is a current
 * in-flight request.
 *
 * If `currentRequestId` is present in the request type, then `singleRequest`
 * must be `true`, otherwise `false`.
 */
type SingleRequestOption<Name extends NameConstraint> =
  SessionState[Name] extends SessionRequestState<
    unknown,
    // A value of "true" on the second generic parameter indicates that the
    // request tracks the current request ID.
    true
  >
    ? {
        /**
         * Whether or not to limit the operation to only a single in-flight
         * request at a time.
         */
        readonly singleRequest: true;
      }
    : {
        /**
         * Whether or not to limit the operation to only a single in-flight
         * request at a time.
         */
        readonly singleRequest?: false;
      };

/**
 * A generic constraint which restricts a type to those of action creators.
 */
type ActionCreatorsConstraint = Record<
  "pending" | "fulfilled" | "rejected",
  | ActionCreatorWithoutPayload
  | ActionCreatorWithPreparedPayload<any, any>
  | AsyncThunk<any, any, any>["pending"]
>;

/**
 * A case reducer accepting a mutable state.
 */
type CaseReducer<Action> = (
  state: Draft<SessionState>,
  action: Action,
) => SessionState | void;

/**
 * The case reducers for each status of a request.
 */
type CaseReducers<ActionCreators extends ActionCreatorsConstraint> = {
  readonly [P in "pending" | "fulfilled" | "rejected"]: CaseReducer<
    ReturnType<ActionCreators[P]>
  >;
};

/**
 * The `caseReducers` option. It is set to optional if the default
 * implementation returns a compatible state type.
 */
type CaseReducersOption<
  Name extends NameConstraint,
  ActionCreators extends ActionCreatorsConstraint,
> = SessionRequestState<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  // A value of "true" on the second generic parameter indicates that the
  // request tracks the current request ID.
  true
> extends SessionState[Name]
  ? {
      readonly caseReducers?: Partial<CaseReducers<ActionCreators>>;
    }
  : {
      readonly caseReducers: CaseReducers<ActionCreators>;
    };

export type AddRequestCaseReducersOptions<
  Name extends NameConstraint,
  ActionCreators extends ActionCreatorsConstraint,
> = SingleRequestOption<Name> &
  CaseReducersOption<Name, ActionCreators> & {
    readonly actionCreators: ActionCreators;
    readonly builder: ActionReducerMapBuilder<SessionState>;
    readonly name: Name;
  };

/**
 * Adds the case reducers for a `session` request operation. It enforces that
 * only a single request can be in-flight for a given request at once.
 */
export function addRequestCaseReducers<
  Name extends NameConstraint,
  ActionCreators extends ActionCreatorsConstraint,
>({
  actionCreators,
  builder,
  caseReducers,
  name,
  singleRequest = false,
}: AddRequestCaseReducersOptions<Name, ActionCreators>) {
  // Handle the pending status.
  // If singleRequest is true, then it enforces that only a single request can
  // be in flight at a time.
  builder.addCase(actionCreators.pending, (state, action) => {
    if (singleRequest) {
      // @ts-expect-error Ignore generic typing issue.
      if (state[name].status === "loading") return;
    }

    if (caseReducers?.pending) {
      return caseReducers.pending(state, action as any);
    }

    // @ts-expect-error Ignore generic typing issue.
    state[name] = {
      // @ts-expect-error Ignore generic typing issue.
      currentRequestId: action.meta.requestId,
      status: "loading",
    };
  });

  // Handle the fulfilled status.
  builder.addCase(actionCreators.fulfilled, (state, action) => {
    if (singleRequest) {
      if (
        // @ts-expect-error Ignore generic typing issue.
        state[name].status !== "loading" ||
        // @ts-expect-error Ignore generic typing issue.
        state[name].currentRequestId !== action.meta.requestId
      ) {
        return;
      }
    }

    if (caseReducers?.fulfilled) {
      return caseReducers.fulfilled(state, action as any);
    }

    // @ts-expect-error Ignore generic typing issue.
    state[name] = {
      status: "success",
    };
  });

  // Handle the rejected status.
  builder.addCase(actionCreators.rejected, (state, action) => {
    if (singleRequest) {
      if (
        // @ts-expect-error Ignore generic typing issue.
        state[name].status !== "loading" ||
        // @ts-expect-error Ignore generic typing issue.
        state[name].currentRequestId !== action.meta.requestId
      ) {
        return;
      }
    }

    if (caseReducers?.rejected) {
      return caseReducers.rejected(state, action as any);
    }

    // @ts-expect-error Ignore generic typing issue.
    state[name] = {
      // @ts-expect-error Ignore generic typing issue.
      error: action.error,
      status: "error",
    };
  });
}
