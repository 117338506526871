import type { User } from "firebase/auth";

import type { SessionUser } from "./SessionUser";

/**
 * Returns a Redux serializable representation of the provided Firebase `User`.
 */
export function createSessionUserFromFirebaseUser(
  firebaseUser: User,
): SessionUser {
  if (firebaseUser.providerData[0]?.providerId === "password") {
    return {
      email: firebaseUser.email,
      emailVerified: firebaseUser.emailVerified,
      provider: "password",
      uid: firebaseUser.uid,
    };
  }

  return {
    email: firebaseUser.email,
    provider: "social",
    uid: firebaseUser.uid,
  };
}
