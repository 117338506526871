import type { StoreServices } from "../store";

/**
 * A type predicate which returns whether or not the provided `value` is a
 * `StoreServices`.
 */
export function isStoreServices(value: unknown): value is StoreServices {
  const firebaseServiceStoreServicesKey: keyof Pick<
    StoreServices,
    "firebaseService"
  > = "firebaseService";

  return (
    typeof value === "object" &&
    value !== null &&
    firebaseServiceStoreServicesKey in value
  );
}
