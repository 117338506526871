import * as t from "io-ts";

export type ApiUserCashbackGetResult = t.TypeOf<
  typeof apiUserCashbackGetResultCodec
>;

export const apiUserCashbackGetResultCodec = t.readonly(
  t.type({
    amount: t.number,
    is_available: t.boolean,
  }),
);
