import { isValid, parseISO } from "date-fns";
import type { Either } from "fp-ts/Either";
import * as t from "io-ts";
import type { Opaque } from "type-fest";

import { logger } from "../utils";

export type Rfc3339String = Opaque<string, "Rfc3339String">;

/**
 * A type predicate which returns `true` if `input` is a `Rfc3339String`.
 *
 * Exposed as `rfc3339StringCodec.is()`.
 */
function is(input: unknown): input is Rfc3339String {
  if (typeof input !== "string") return false;

  try {
    const date = parseISO(input);
    return isValid(date);
  } catch (error) {
    logger.warn("Failed to parse RFC 3339 date:", error);
    return false;
  }
}

/**
 * Returns either a success object, with the validated date string, or failure
 * object.
 *
 * Exposed as `rfc3339StringCodec.decode()`.
 */
function validate(
  input: unknown,
  context: t.Context,
): Either<t.Errors, Rfc3339String> {
  return is(input) ? t.success(input) : t.failure(input, context);
}

/**
 * Returns the `Rfc3339String` as a `string`.
 *
 * Exposed as `rfc3339StringCodec.encode()`.
 */
function encode(input: Rfc3339String): string {
  return input;
}

/**
 * Validates that the provided string is formatted as an RFC 3339 date string.
 */
export const rfc3339StringCodec = new t.Type(
  "rfc3339String",
  is,
  validate,
  encode,
);
