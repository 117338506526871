import { getAuth, User } from "firebase/auth";
import type {
  Action,
  AnyAction,
  Dispatch,
  PreloadedState,
  Reducer,
  Store,
  StoreEnhancer,
  StoreEnhancerStoreCreator,
} from "redux";

import { resetApiState } from "../api";
import type { FirebaseService } from "../firebase";
import { signUpClear } from "../sign-up";
import type { StoreServices } from "../store";
import { createSessionUserFromFirebaseUser } from "./createSessionUserFromFirebaseUser";
import { sendBrowserExtensionMessage } from "./sendBrowserExtensionMessage";
import { sessionChangeCurrent, sessionSignOut } from "./sessionSlice";

export type CreateSessionStoreEnhancerOptions = {
  readonly services: StoreServices;
};

async function sendBrowserExtensionAuthData(firebaseUser: User) {
  try {
    const idToken = await firebaseUser.getIdToken();
    await sendBrowserExtensionMessage({
      data: {
        email: firebaseUser.email,
        id_token: idToken,
        user_id: firebaseUser.uid,
      },
      type: "SET_AUTH_DATA",
    });
    console.log("Sent credentials to browser extension.");
  } catch (error) {
    console.error("Failed to send credentials to browser extension:", error);
  }
}

async function sendBrowserExtensionSignOutMessage() {
  try {
    await sendBrowserExtensionMessage({ type: "REMOVE_AUTH_DATA" });
    console.log("Sent sign out message to browser extension.");
  } catch (error) {
    console.error(
      "Failed to send sign out message to browser extension:",
      error,
    );
  }
}

function wrappedReducer<S = never, A extends Action = AnyAction>(
  reducer: Reducer<S, A>,
  state: S | undefined,
  action: A,
): S {
  // When the user invokes the sign out operation, reset the store state and
  // inform the browser extension.
  if (sessionSignOut.fulfilled.match(action)) {
    sendBrowserExtensionSignOutMessage();
    return reducer(undefined, action);
  }

  return reducer(state, action);
}

function handleAuthStateChangeError(dispatch: Dispatch, error: unknown) {
  dispatch(sessionChangeCurrent.rejected({ error }));
}

function handleAuthStateChange(
  dispatch: Dispatch,
  firebaseService: FirebaseService,
  firebaseUser: User | null,
) {
  firebaseService.user = firebaseUser;
  const sessionUser = firebaseUser
    ? createSessionUserFromFirebaseUser(firebaseUser)
    : null;
  dispatch(sessionChangeCurrent.fulfilled({ user: sessionUser }));

  // Clear user details.
  if (!firebaseUser) {
    dispatch(resetApiState());
    dispatch(signUpClear());
    return;
  }

  // Send the credentials to the browser extension.
  sendBrowserExtensionAuthData(firebaseUser);
}

function storeEnhancerStoreCreator<S = never, A extends Action = AnyAction>(
  firebaseService: FirebaseService,
  next: StoreEnhancerStoreCreator,
  reducer: Reducer<S, A>,
  preloadedState?: PreloadedState<S>,
): Store<S, A> {
  const store: Store<S, AnyAction> = next<S, A>((state, action) => {
    return wrappedReducer<S, A>(reducer, state, action);
  }, preloadedState);

  // Monitor the Firebase authentication status. Dispatch Redux action to update
  // authentication state and dispatch message to browser extension.
  const firebaseApp = firebaseService.getApp();
  const auth = getAuth(firebaseApp);
  store.dispatch(sessionChangeCurrent.pending());
  auth.onAuthStateChanged({
    complete: () => {
      // Noop.
    },
    error: (error) => {
      handleAuthStateChangeError(store.dispatch, error);
    },
    next: (firebaseUser) => {
      handleAuthStateChange(store.dispatch, firebaseService, firebaseUser);
    },
  });

  return store as Store<S, A>;
}

function storeEnhancer(
  firebaseService: FirebaseService,
  next: StoreEnhancerStoreCreator,
): StoreEnhancerStoreCreator {
  return ((reducer, preloadedState) => {
    return storeEnhancerStoreCreator(
      firebaseService,
      next,
      reducer,
      preloadedState,
    );
    // TODO: Fix the type error.
  }) as StoreEnhancerStoreCreator;
}

export function createSessionStoreEnhancer({
  services: { firebaseService },
}: CreateSessionStoreEnhancerOptions): StoreEnhancer {
  return (next) => {
    return storeEnhancer(firebaseService, next);
  };
}
