type Logger = Pick<typeof console, "debug" | "error" | "log" | "warn">;

export const logger = ((): Logger => ({
  debug(...args) {
    if (process.env.GATSBY_NODE_ENV === "production") {
      return;
    }

    console.debug(...args);
  },
  log(...args) {
    console.log(...args);
  },
  warn(...args) {
    console.warn(...args);
  },
  error(...args) {
    console.error(...args);
  },
}))();
