import { formatRFC3339, isBefore, parseISO } from "date-fns";
import * as z from "zod";

import { createCurriculumDummyLessonsDto } from "../../test-data/createCurriculumDummyLessonsDto";
import type { CurriculumApiDtoError } from "../dto/CurriculumApiDtoError";
import type { CurriculumApiDtoLesson } from "../dto/CurriculumApiDtoLesson";
import { curriculumApiDtoLessonPatchSchema } from "../dto/CurriculumApiDtoLessonPatch";
import type { CurriculumApiDtoNewStudent } from "../dto/CurriculumApiDtoNewStudent";
import type { CurriculumApiDtoStudent } from "../dto/CurriculumApiDtoStudent";
import { curriculumApiDtoStudentCreateSchema } from "../dto/CurriculumApiDtoStudentCreate";
import type { CurriculumApiRequestGetStudentLessons } from "../request/CurriculumApiRequestGetStudentLessons";
import type { CurriculumApiResponseAddStudent } from "../response/CurriculumApiResponseAddStudent";
import type { CurriculumApiResponseFindStudentById } from "../response/CurriculumApiResponseFindStudentById";
import type { CurriculumApiResponseGetStudentLessonById } from "../response/CurriculumApiResponseGetStudentLessonById";
import type { CurriculumApiResponseGetStudentLessons } from "../response/CurriculumApiResponseGetStudentLessons";
import {
  type CurriculumLessonId,
  type CurriculumStudentId,
  curriculumDateSchema,
  curriculumLessonIdSchema,
  curriculumStudentIdSchema,
} from "../types";
import { addMockLatency } from "./addMockLatency";
import { getBody } from "./getBody";
import { getMethod } from "./getMethod";
import { getRelativePath } from "./getRelativePath";

function createApiErrorResponse(
  status: number,
  statusText: string,
  message: string,
  details?: unknown,
): Response {
  const error: CurriculumApiDtoError & {
    details?: unknown;
  } = {
    code: status,
    message,
  };
  if (details) error.details = details;

  return new Response(JSON.stringify(error), {
    status,
    statusText,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createBadRequestResponse(
  message: string,
  details?: unknown,
): Response {
  return createApiErrorResponse(400, "Bad Request", message, details);
}

function createMissingBodyErrorResponse(): Response {
  return createBadRequestResponse("body empty");
}

function createValidationErrorResponse(zodError: z.ZodError): Response {
  return createBadRequestResponse("body failed validation", zodError);
}

function createUnprocessableEntityResponse(
  message: string,
  details?: unknown,
): Response {
  return createApiErrorResponse(422, "UnprocessableEntity", message, details);
}

function createJsonResponse(
  body: Record<string, unknown> | readonly unknown[],
): Response {
  return new Response(JSON.stringify(body), {
    status: 200,
    statusText: "OK",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function createNotFoundResponse(
  message = "not found",
  details?: unknown,
): Response {
  return createApiErrorResponse(404, "Not Found", message, details);
}

const students: CurriculumApiDtoStudent[] = [];

let lessonsByStudentId: Record<
  CurriculumStudentId,
  Record<CurriculumLessonId, CurriculumApiDtoLesson>
> = {};

export async function curriculumApiFetchMock(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> {
  await addMockLatency();

  console.log("curriculumApiFetchMock:", {
    input,
    init,
  });

  const relativePath = getRelativePath(input);
  const relativePathSegments = relativePath.split("/").slice(1);
  const method = getMethod(input, init);
  const body = await getBody(input, init);

  console.log("curriculumApiFetchMock:", {
    relativePath,
    relativePathSegments,
    method,
    body,
  });

  // addStudent
  if (
    relativePathSegments.length === 1 &&
    relativePathSegments[0] === "students" &&
    method === "POST"
  ) {
    if (!body) return createMissingBodyErrorResponse();
    const result = curriculumApiDtoStudentCreateSchema.safeParse(body);
    if (!result.success) return createValidationErrorResponse(result.error);

    const student = result.data;
    if (students.some((s) => s.id === student.id)) {
      return createUnprocessableEntityResponse("student already exists");
    }
    students.push({
      ...student,
      // The API server populates this field.
      created: curriculumDateSchema.parse(formatRFC3339(new Date())),
    });

    lessonsByStudentId[student.id] = createCurriculumDummyLessonsDto(
      student.student_type,
    ).reduce((accumulator, lesson) => {
      if (lesson.id) accumulator[lesson.id] = lesson;
      return accumulator;
    }, {} as Record<CurriculumLessonId, CurriculumApiDtoLesson>);

    const newStudent: CurriculumApiDtoNewStudent = {
      student_id: student.id,
      student_type: student.student_type,
    };
    const response: CurriculumApiResponseAddStudent = newStudent;
    return createJsonResponse(response);
  }

  // findStudentByID
  if (
    relativePathSegments.length === 2 &&
    relativePathSegments[0] === "students" &&
    relativePathSegments[1] &&
    method === "GET"
  ) {
    const studentId = relativePathSegments[1];
    const student = students.find((s) => s.id === studentId);
    if (!student) return createNotFoundResponse("student not found", studentId);

    const response: CurriculumApiResponseFindStudentById = student;
    return createJsonResponse(response);
  }

  // getStudentLessons
  if (
    (relativePathSegments.length === 3 || relativePathSegments.length === 4) &&
    relativePathSegments[0] === "students" &&
    relativePathSegments[1] &&
    relativePathSegments[2] === "lessons" &&
    method === "GET"
  ) {
    const studentId = curriculumStudentIdSchema.parse(relativePathSegments[1]);

    type StateQueryValue = NonNullable<
      NonNullable<CurriculumApiRequestGetStudentLessons["query"]>["state"]
    >;
    const stateQueryValueMap: Record<StateQueryValue, true> = {
      available: true,
      completed: true,
      incomplete: true,
    };
    const stateQuery =
      relativePathSegments[3] &&
      Object.keys(stateQueryValueMap).includes(relativePathSegments[3])
        ? (relativePathSegments[3] as StateQueryValue)
        : null;

    const lessons = Object.values(lessonsByStudentId[studentId] || {}).filter(
      (lesson) => {
        if (!stateQuery) return true;

        const state: StateQueryValue | null = lesson.complete_date
          ? "completed"
          : lesson.start_date
          ? "incomplete"
          : lesson.lesson_available_date &&
            isBefore(parseISO(lesson.lesson_available_date), new Date())
          ? "available"
          : null;
        return stateQuery === state;
      },
    );

    const response: CurriculumApiResponseGetStudentLessons = lessons;
    return createJsonResponse(response);
  }

  if (
    relativePathSegments.length === 4 &&
    relativePathSegments[0] === "students" &&
    relativePathSegments[1] &&
    relativePathSegments[2] === "lesson" &&
    relativePathSegments[3]
  ) {
    const studentId = curriculumStudentIdSchema.parse(relativePathSegments[1]);
    const lessonId = curriculumLessonIdSchema.parse(relativePathSegments[3]);
    const lesson = Object.values(lessonsByStudentId[studentId] || {}).find(
      (lesson) => lesson.id === lessonId,
    );
    if (!lesson) return createNotFoundResponse("lesson not found", lessonId);

    // getStudentLessonById
    if (method === "GET") {
      const response: CurriculumApiResponseGetStudentLessonById = lesson;
      return createJsonResponse(response);
    }

    // patchStudentLessonById
    if (method === "PATCH" && body) {
      const result = z.array(curriculumApiDtoLessonPatchSchema).safeParse(body);
      if (!result.success) return createValidationErrorResponse(result.error);
      const patches = result.data;

      console.log("Before:", { lessonsByStudentId });
      lessonsByStudentId = Object.entries(lessonsByStudentId).reduce(
        (accumulator, [recordStudentIdUntyped, recordLessons]) => {
          const recordStudentId = recordStudentIdUntyped as CurriculumStudentId;
          if (recordStudentId !== studentId) {
            accumulator[recordStudentId] = recordLessons;
            return accumulator;
          }

          accumulator[recordStudentId as CurriculumStudentId] = Object.entries(
            recordLessons,
          ).reduce((accumulator, [recordLessonIdUntyped, recordLesson]) => {
            const recordLessonId = recordLessonIdUntyped as CurriculumLessonId;
            if (recordLessonId !== lessonId) {
              accumulator[recordLessonId] = recordLesson;
              return accumulator;
            }

            accumulator[recordLessonId] = patches.reduce(
              (accumulator, patch) => {
                switch (patch.key) {
                  case "CompletedDate": {
                    return {
                      ...accumulator,
                      complete_date: patch.value,
                    };
                  }

                  case "Progress": {
                    return {
                      ...accumulator,
                      progress: JSON.parse(patch.value),
                    };
                  }

                  case "ReportedWeeklyActivityInMinutes": {
                    return {
                      ...accumulator,
                      reported_weekly_activity_in_minutes: parseInt(
                        patch.value,
                        10,
                      ),
                    };
                  }

                  case "ReportedWeeklyBodyWeightInLbs": {
                    return {
                      ...accumulator,
                      reported_weekly_body_weight_in_lbs: parseFloat(
                        patch.value,
                      ),
                    };
                  }

                  case "StartDate": {
                    return {
                      ...accumulator,
                      start_date: patch.value,
                    };
                  }
                }
              },
              recordLesson,
            );
            return accumulator;
          }, {} as Record<CurriculumLessonId, CurriculumApiDtoLesson>);
          return accumulator;
        },
        {} as typeof lessonsByStudentId,
      );
      console.log("After:", { lessonsByStudentId });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const lessons = Object.values(lessonsByStudentId[studentId]!);
      return createJsonResponse(lessons);
    }
  }

  return createNotFoundResponse("unrecognized route", relativePath);
}
