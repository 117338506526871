import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import type { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import {
  analyticsReducer,
  analyticsReducerPath,
  createAnalyticsMiddleware,
} from "../analytics";
import { apiMiddleware, apiReducer, apiReducerPath } from "../api";
import {
  curriculumApiMiddleware,
  curriculumApiReducer,
  curriculumApiReducerPath,
} from "../curriculum";
import type { FirebaseService } from "../firebase";
import {
  rewardsApiMiddleware,
  rewardsApiReducer,
  rewardsApiReducerPath,
} from "../rewards";
import {
  createSessionStoreEnhancer,
  sessionReducer,
  sessionReducerPath,
} from "../session";
import { signUpReducer, signUpReducerPath } from "../sign-up";
import { errorReducer } from "./reducers/errorReducer";
import isLoadingReducer from "./reducers/isLoadingReducer";

/**
 * Services exposed to store middleware.
 */
export type StoreServices = {
  /**
   * The URL which is sent to users during the email verification to continue
   * back to the app.
   */
  readonly emailVerificationContinueUrl: string;

  /**
   * The Facebook authentication provider used for Facebook social log ins.
   */
  readonly facebookAuthProvider: FacebookAuthProvider;

  /**
   * The Firebase app instance provider.
   */
  readonly firebaseService: FirebaseService;

  /**
   * The Google authentication provider used for Google social log in.
   */
  readonly googleAuthProvider: GoogleAuthProvider;

  /**
   * The URL which is sent to users during the password reset flow to continue
   * back to the app.
   */
  readonly passwordResetEmailContinueUrl: string;
};

export type ConfigureStoreOptions = {
  /**
   * Services exposed to store middleware.
   */
  readonly services: StoreServices;
};

/**
 * The root Redux store state.
 */
export type RootState = ReturnType<
  ReturnType<typeof configureStore>["getState"]
>;

/**
 * The Redux store `dispatch` method, typed to include the configured
 * middlewares.
 */
export type AppDispatch = ReturnType<typeof configureStore>["dispatch"];

/**
 * Creates a new Redux store instance.
 */
export function configureStore({ services }: ConfigureStoreOptions) {
  const analyticsMiddleware = createAnalyticsMiddleware({ services });
  const sessionStoreEnhancer = createSessionStoreEnhancer({ services });

  const store = reduxConfigureStore({
    enhancers: [sessionStoreEnhancer],
    middleware: (getDefaultMiddleware) =>
      // Use the "concat" method to chain the additional middleware to preserve
      // type information.
      getDefaultMiddleware({
        thunk: {
          extraArgument: services,
        },
      }).concat(
        apiMiddleware,
        analyticsMiddleware,
        curriculumApiMiddleware,
        rewardsApiMiddleware,
      ),
    reducer: {
      [analyticsReducerPath]: analyticsReducer,
      [apiReducerPath]: apiReducer,
      [curriculumApiReducerPath]: curriculumApiReducer,
      error: errorReducer,
      isLoading: isLoadingReducer,
      [rewardsApiReducerPath]: rewardsApiReducer,
      [sessionReducerPath]: sessionReducer,
      [signUpReducerPath]: signUpReducer,
    },
  });

  return store;
}

/**
 * A re-export of the Redux `useDispatch` hook, typed to include the configured
 * middlewares.
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * A re-export of the Redux `useSelector` hook, typed to use the store state
 * type.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
