import type { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatRFC3339 } from "date-fns";

import { curriculumApiBaseUrl } from "./curriculumApiBaseUrl";
import type { CurriculumApiDtoLessonPatch } from "./dto/CurriculumApiDtoLessonPatch";
import { curriculumApiDtoLessonPatch_ProgressStringifiedSchema } from "./dto/CurriculumApiDtoLessonPatch_ProgressStringified";
import { curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema } from "./dto/CurriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringified";
import { curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema } from "./dto/CurriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringified";
import type { CurriculumApiDtoStudentCreate } from "./dto/CurriculumApiDtoStudentCreate";
import { curriculumApiFetchMock } from "./mock";
import { normalizeCurriculumDtoLesson } from "./normalizeCurriculumDtoLesson";
import { normalizeCurriculumDtoLessons } from "./normalizeCurriculumDtoLessons";
import type { CurriculumApiRequestAddStudent } from "./request/CurriculumApiRequestAddStudent";
import type { CurriculumApiRequestPatchStudentLessonById } from "./request/CurriculumApiRequestPatchStudentLessonById";
import { curriculumApiResponseAddStudentSchema } from "./response/CurriculumApiResponseAddStudent";
import { curriculumApiResponseFindStudentByIdSchema } from "./response/CurriculumApiResponseFindStudentById";
import { curriculumApiResponseGetStudentLessonsSchema } from "./response/CurriculumApiResponseGetStudentLessons";
import { curriculumApiResponsePatchStudentLessonByIdSchema } from "./response/CurriculumApiResponsePatchStudentLessonById";
import {
  type CurriculumClassType,
  type CurriculumLesson,
  type CurriculumPatchLessonOptions,
  type CurriculumStudent,
  type CurriculumStudentId,
  type CurriculumSwapUserId,
  curriculumDateSchema,
  curriculumStudentIdSchema,
} from "./types";

function getStudentIdFromSwapUserId(
  swapUserId: CurriculumSwapUserId,
): CurriculumStudentId {
  // The Education API uses the convention [swapUserId]-[classType] for student
  // IDs. For example, if the swap user ID is "LaXYeuLGzubjIBMqUHMnZ0ns7QD2",
  // then the student ID for a class type of "dpp" would be:
  // "LaXYeuLGzubjIBMqUHMnZ0ns7QD2-dpp".
  const classType: CurriculumClassType = "dpp";
  const studentId = curriculumStudentIdSchema.parse(
    `${swapUserId}-${classType}`,
  );
  return studentId;
}

// TODO: Remove this development-only code once the Education API has been
// deployed.
const fetchBaseQueryArgs: FetchBaseQueryArgs = process.env
  .GATSBY_EDUCATIONAL_API_DISABLE_DEV_MOCK
  ? {
      baseUrl: curriculumApiBaseUrl,
    }
  : {
      baseUrl: curriculumApiBaseUrl,
      // TODO: Remove this mock fetch implementation once the feature is launched.
      fetchFn: curriculumApiFetchMock,
    };

const curriculumApi = createApi({
  baseQuery: fetchBaseQuery(fetchBaseQueryArgs),

  endpoints: (builder) => ({
    createStudent: builder.mutation<CurriculumStudent, CurriculumSwapUserId>({
      query: (swapUserId) => {
        const studentId = getStudentIdFromSwapUserId(swapUserId);
        const student: CurriculumApiDtoStudentCreate = {
          id: studentId,
          student_type: "dpp",
          swap_user_id: swapUserId,
        };
        const request: CurriculumApiRequestAddStudent = {
          body: student,
          operationId: "addStudent",
        };

        return {
          body: JSON.stringify(request.body),
          headers: { "Content-Type": "application/json" },
          method: "POST",
          url: "/students",
        };
      },

      transformResponse: (response, _meta, arg) => {
        const responseAddStudent =
          curriculumApiResponseAddStudentSchema.parse(response);
        const student: CurriculumStudent = {
          dateCreated: curriculumDateSchema.parse(formatRFC3339(new Date())),
          studentId: responseAddStudent.student_id,
          studentType: responseAddStudent.student_type,
          swapUserId: arg,
        };
        return student;
      },

      invalidatesTags: ["CurriculumStudent"],
    }),

    getLessons: builder.query<
      readonly CurriculumLesson[],
      CurriculumSwapUserId
    >({
      query: (swapUserId) => {
        const studentId = getStudentIdFromSwapUserId(swapUserId);
        return `/students/${studentId}/lessons`;
      },

      transformResponse: (response) => {
        const responseGetStudentLessons =
          curriculumApiResponseGetStudentLessonsSchema.parse(response);
        return normalizeCurriculumDtoLessons(responseGetStudentLessons);
      },

      providesTags: ["CurriculumLesson"],
    }),

    getStudent: builder.query<CurriculumStudent, CurriculumSwapUserId>({
      query: (swapUserId) => {
        const studentId = getStudentIdFromSwapUserId(swapUserId);
        return `/students/${studentId}`;
      },

      transformResponse: (response) => {
        const responseFindStudentById =
          curriculumApiResponseFindStudentByIdSchema.parse(response);
        return {
          dateCreated: responseFindStudentById.created,
          studentId: responseFindStudentById.id,
          studentType: responseFindStudentById.student_type,
          swapUserId: responseFindStudentById.swap_user_id,
        };
      },

      providesTags: ["CurriculumStudent"],
    }),

    patchLesson: builder.mutation<
      CurriculumLesson | null,
      CurriculumPatchLessonOptions
    >({
      invalidatesTags: ["CurriculumLesson"],

      query: ({ lessonId, patches, swapUserId }) => {
        const studentId = getStudentIdFromSwapUserId(swapUserId);
        const request: CurriculumApiRequestPatchStudentLessonById = {
          body: patches.map((patch): CurriculumApiDtoLessonPatch => {
            switch (patch.key) {
              case "completedDate": {
                return {
                  key: "CompletedDate",
                  value: patch.value,
                };
              }

              case "progress": {
                return {
                  key: "Progress",
                  value:
                    curriculumApiDtoLessonPatch_ProgressStringifiedSchema.parse(
                      JSON.stringify(patch.value),
                    ),
                };
              }

              case "reportedWeeklyActivityInMinutes": {
                return {
                  key: "ReportedWeeklyActivityInMinutes",
                  value:
                    curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema.parse(
                      patch.value.toString(),
                    ),
                };
              }

              case "reportedWeeklyBodyWeightInLbs": {
                return {
                  key: "ReportedWeeklyBodyWeightInLbs",
                  value:
                    curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema.parse(
                      patch.value.toString(),
                    ),
                };
              }

              case "startDate": {
                return {
                  key: "StartDate",
                  value: patch.value,
                };
              }
            }
          }),
          operationId: "patchStudentLessonById",
          path: { lessonId, studentId },
        };

        return {
          body: JSON.stringify(request.body),
          headers: { "Content-Type": "application/json" },
          method: "PATCH",
          url: `/students/${studentId}/lesson/${lessonId}`,
        };
      },

      transformResponse: (response) => {
        console.log({ response });
        const responsePatchStudentLessonById =
          curriculumApiResponsePatchStudentLessonByIdSchema.parse(response);
        return normalizeCurriculumDtoLesson(responsePatchStudentLessonById);
      },
    }),
  }),

  reducerPath: "curriculum",

  tagTypes: ["CurriculumLesson", "CurriculumStudent"],
});

export const {
  middleware: curriculumApiMiddleware,
  reducer: curriculumApiReducer,
  reducerPath: curriculumApiReducerPath,
  useCreateStudentMutation: useCurriculumApiCreateStudentMutation,
  useGetLessonsQuery: useCurriculumApiGetLessonsQuery,
  useGetStudentQuery: useCurriculumApiGetStudentQuery,
  usePatchLessonMutation: useCurriculumApiPatchLessonMutation,
} = curriculumApi;
