import type { Opaque } from "type-fest";
import * as z from "zod";

/**
 * A unique ID of a student enrolled in a particular class type.
 *
 * A Swap user can have multiple associated student instances, but only one per
 * class type.
 */
export type CurriculumStudentId = z.infer<typeof curriculumStudentIdSchema>;

/**
 * The validation schema for `CurriculumStudentId`.
 *
 * @see {CurriculumStudentId}
 */
export const curriculumStudentIdSchema = z
  .string()
  .trim()
  .min(1)
  .transform((arg) => arg as Opaque<string, "CurriculumStudentId">);
