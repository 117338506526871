import * as z from "zod";

/**
 * The type of the class a user is enrolled in.
 */
export type CurriculumClassType = z.infer<typeof curriculumClassTypeSchema>;

/**
 * The validation schema for `CurriculumClassType`.
 *
 * @see {CurriculumClassType}
 */
export const curriculumClassTypeSchema = z.enum(["dpp"]);
