import type * as t from "io-ts";

import type { ApiArgs } from "./ApiArgs";
import { apiUserCashbackEventCreateResultCodec } from "./ApiUserCashbackEventCreateResult";
import { apiUserCashbackGetResultCodec } from "./ApiUserCashbackGetResult";
import { apiUserCreateResultCodec } from "./ApiUserCreateResult";
import { apiUserGetResultCodec } from "./ApiUserGetResult";
import { apiUserUpdateResultCodec } from "./ApiUserUpdateResult";

type CreateOperationCodecMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<ApiArgs["operation"], t.Type<any, any, unknown>>,
> = Readonly<T>;

type OperationCodecMap = CreateOperationCodecMap<{
  userCashbackEventCreate: typeof apiUserCashbackEventCreateResultCodec;
  userCashbackGet: typeof apiUserCashbackGetResultCodec;
  userCreate: typeof apiUserCreateResultCodec;
  userGet: typeof apiUserGetResultCodec;
  userUpdate: typeof apiUserUpdateResultCodec;
}>;

/**
 * Returns the validation codec for the specified operation.
 *
 * @param args The request arguments.
 * @returns The validation codec for the response.
 */
export function getResponseCodec(
  args: ApiArgs,
): OperationCodecMap[keyof OperationCodecMap] {
  switch (args.operation) {
    case "userCashbackEventCreate": {
      return apiUserCashbackEventCreateResultCodec;
    }

    case "userCashbackGet": {
      return apiUserCashbackGetResultCodec;
    }

    case "userCreate": {
      return apiUserCreateResultCodec;
    }

    case "userGet": {
      return apiUserGetResultCodec;
    }

    case "userUpdate": {
      return apiUserUpdateResultCodec;
    }
  }
}
