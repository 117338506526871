import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { RewardsApiSubmitArgs } from "./RewardsApiSubmitArgs";

const rewardsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      "https://script.google.com/macros/s/AKfycbz5163Kx0UPYLPvqDIQ5qUqjMfmR2Qya5A6Ny14Ot7hXESlPV0BLDuh91AnMM7KrprkGw/exec",
  }),
  endpoints: (builder) => ({
    submit: builder.mutation<void, RewardsApiSubmitArgs>({
      query: (body) => ({
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        url: "",
      }),
    }),
  }),
  reducerPath: "rewards",
});

export const {
  middleware: rewardsApiMiddleware,
  reducer: rewardsApiReducer,
  reducerPath: rewardsApiReducerPath,
  useSubmitMutation,
} = rewardsApi;
