import type { AnalyticsEvent } from "./AnalyticsEvent";

// #region Type Definitions

/**
 * The name of the field holding the event name, i.e., `eventName`.
 */
type AnalyticsEventEventNameField = keyof Pick<AnalyticsEvent, "eventName">;

/**
 * A union of the possible analytics event names.
 */
type AnalyticsEventName = AnalyticsEvent[AnalyticsEventEventNameField];

/**
 * The versions supported for the analytics event specified by `EventName`.
 */
type AnalyticsEventVersionByEventName<EventName extends AnalyticsEventName> =
  Extract<AnalyticsEvent, { eventName: EventName }>["eventVersion"];

type RequiresUserAuthenticationByEventNameBase<
  Mapping extends Record<AnalyticsEventName, boolean>,
> = Mapping;

/**
 * A mapping of event names to whether or not authentication is required.
 */
type RequiresUserAuthenticationByEventName =
  RequiresUserAuthenticationByEventNameBase<{
    accountCreationCompleted: true;
    // At this point in the flow, an email address has been captured but the
    // signup has not be completed.
    accountCreationStarted: false;
    associatedWithCohort: true;
    cashOutRequested: true;
    emailVerified: true;
    extensionInstalled: true;
    extensionInteractedWith: true;
    intervention: true;
    minibadgeInteractedWith: true;
    onboardingCompleted: true;
    orderDelivered: true;
    orderPlaced: true;
    orderStarted: true;
    // The user won't be authenticated if they can't sign in.
    passwordResetRequested: false;
    passwordSuccessfullyReset: true;
    postOrderSurveySubmitted: true;
    viewedPdpPage: true;
  }>;

/**
 * Common config fields for every `AnalyticsEvent`.
 */
type AnalyticsEventCommonConfig<EventName extends AnalyticsEventName> = {
  /**
   * Whether or not a user must be authenticated to submit the event.
   *
   * Backend services can still submit the event.
   */
  readonly requiresUserAuthentication: RequiresUserAuthenticationByEventName[EventName];
};

/**
 * Config fields for each version of an event.
 */
type AnalyticsEventVersionConfigs<EventName extends AnalyticsEventName> = {
  readonly [EventVersion in AnalyticsEventVersionByEventName<EventName> as `version${EventVersion}`]: true;
};

/**
 * A mapping of events to their config.
 */
type AnalyticsEventConfigMap = {
  readonly [EventName in AnalyticsEventName]: AnalyticsEventCommonConfig<EventName> &
    AnalyticsEventVersionConfigs<EventName>;
};

// #endregion Type Definitions

/**
 * A mapping of events to their config.
 */
export const analyticsEventConfigMap: AnalyticsEventConfigMap = {
  accountCreationCompleted: {
    requiresUserAuthentication: true,
    version0: true,
  },
  accountCreationStarted: {
    // At this point in the flow, an email address has been captured but the
    // signup has not be completed.
    requiresUserAuthentication: false,
    version0: true,
  },
  associatedWithCohort: {
    requiresUserAuthentication: true,
    version0: true,
  },
  cashOutRequested: {
    requiresUserAuthentication: true,
    version0: true,
  },
  emailVerified: {
    requiresUserAuthentication: true,
    version0: true,
  },
  extensionInstalled: {
    requiresUserAuthentication: true,
    version0: true,
  },
  extensionInteractedWith: {
    requiresUserAuthentication: true,
    version0: true,
  },
  intervention: {
    requiresUserAuthentication: true,
    version0: true,
  },
  minibadgeInteractedWith: {
    requiresUserAuthentication: true,
    version0: true,
  },
  onboardingCompleted: {
    requiresUserAuthentication: true,
    version0: true,
  },
  orderDelivered: {
    requiresUserAuthentication: true,
    version0: true,
  },
  orderPlaced: {
    requiresUserAuthentication: true,
    version0: true,
  },
  orderStarted: {
    requiresUserAuthentication: true,
    version0: true,
  },
  passwordResetRequested: {
    // The user won't be authenticated if they can't sign in.
    requiresUserAuthentication: false,
    version0: true,
  },
  passwordSuccessfullyReset: {
    requiresUserAuthentication: true,
    version0: true,
  },
  postOrderSurveySubmitted: {
    requiresUserAuthentication: true,
    version0: true,
  },
  viewedPdpPage: {
    requiresUserAuthentication: true,
    version0: true,
  },
};
