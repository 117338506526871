export type SendBrowserExtensionMessageMessage =
  | {
      readonly type: "REMOVE_AUTH_DATA";
    }
  | {
      readonly data: {
        readonly email: string | null;
        readonly id_token: string;
        readonly user_id: string;
      };
      readonly type: "SET_AUTH_DATA";
    };

/**
 * Sends a message, either a sign out notification or an update to the current
 * credentials, to the browser extension.
 */
export function sendBrowserExtensionMessage(
  message: SendBrowserExtensionMessageMessage,
): Promise<unknown> {
  // Access the "chrome" property off of the "window" object to avoid type
  // errors in non-Chrome browsers.
  if (
    typeof window.chrome === "undefined" ||
    !window.chrome.runtime ||
    !window.chrome.runtime.sendMessage
  ) {
    return Promise.resolve();
  }

  return new Promise<unknown>((resolve, reject) => {
    window.chrome.runtime.sendMessage(
      process.env.GATSBY_EXTENSION_ID,
      message,
      (response: unknown) => {
        if (window.chrome.runtime.lastError) {
          reject(window.chrome.runtime.lastError);
          return;
        }
        resolve(response);
      },
    );
  });
}
