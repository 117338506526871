import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";

import { FirebaseService } from "../firebase";
import type { StoreServices } from "./configureStore";

/**
 * Initializes the services which are injected into the Redux store.
 */
export function initializeServices(): StoreServices {
  return {
    emailVerificationContinueUrl: `${process.env.GATSBY_WEB_URL}/update-profile?congratulation=true`,
    facebookAuthProvider: new FacebookAuthProvider(),
    firebaseService: new FirebaseService(),
    googleAuthProvider: new GoogleAuthProvider(),
    passwordResetEmailContinueUrl: `${process.env.GATSBY_WEB_URL}/reset-password`,
  };
}
