import type { Opaque } from "type-fest";
import * as z from "zod";

/**
 * A unique ID identifying a lesson.
 */
export type CurriculumLessonId = Opaque<string, "CurriculumLessonId">;

/**
 * Validation schema for `CurriculumApiDtoLessonId`.
 *
 * @see {CurriculumLessonId}
 */
export const curriculumLessonIdSchema = z
  .string()
  .min(1)
  .transform((arg) => arg as CurriculumLessonId);
