import * as z from "zod";

/**
 * The progress of a user through a lesson.
 *
 * This is the patch variant, used when updating a student's lesson record using
 * the `patchStudentLessonById` operation. It requires that each field be
 * provided, as apposed to them being optional when retrieved during a GET
 * request.
 */
export type CurriculumApiDtoLessonPatch_Progress = z.infer<
  typeof curriculumApiDtoLessonPatch_ProgressSchema
>;

export const curriculumApiDtoLessonPatch_ProgressSchema = z.object({
  current_page: z.number().int(),
  current_section: z.number().int(),
});
