import {
  type AnyAction,
  type Dispatch,
  type Middleware,
  type MiddlewareAPI,
  type PayloadAction,
  type SerializedError,
  createSlice,
  miniSerializeError,
  nanoid,
} from "@reduxjs/toolkit";
import { getIdToken } from "firebase/auth";
import type { Writable } from "type-fest";

import type { FirebaseService } from "../firebase";
import { sessionSendPasswordResetEmail } from "../session";
import { signUpClear, signUpSetFields } from "../sign-up";
import type { StoreServices } from "../store";
import type { AnalyticsEvent } from "./AnalyticsEvent";
import { analyticsEventConfigMap } from "./analyticsEventConfigMap";
import type { AnalyticsQueueEventsRequest } from "./AnalyticsQueueEventsRequest";

type EnqueuedEvent = AnalyticsEvent & {
  /**
   * The ID of the event in the submission queue.
   */
  readonly queueId: string;
};

type SubmittedEventBase = AnalyticsEvent;
type SubmittedEventFailed = SubmittedEventBase & {
  readonly submissionError: SerializedError;
  readonly submissionStatus: "failed";
};
type SubmittedEventSuccess = SubmittedEventBase & {
  readonly submissionStatus: "success";
};
type SubmittedEvent = SubmittedEventFailed | SubmittedEventSuccess;

type EventsSubmissionBase = {
  readonly time: string;
};
type EventsSubmissionFailed = EventsSubmissionBase & {
  readonly events: AnalyticsEvent[];
  readonly error: SerializedError;
  readonly result: "failed";
};
type EventsSubmissionSuccess = EventsSubmissionBase & {
  readonly events: SubmittedEvent[];
  readonly result: "success";
};
type EventsSubmission = EventsSubmissionFailed | EventsSubmissionSuccess;

type AnalyticsState = {
  /**
   * Enqueued events awaiting submission.
   */
  readonly enqueuedEvents: EnqueuedEvent[];

  /**
   * An email address captured during the enrollment process or a password reset
   * request..
   *
   * This is used to emit events before the user has finished registration. It
   * is captured from the sign up form or password reset form.
   */
  readonly temporaryEmail: string | null;

  readonly submissions: EventsSubmission[];
};

type StateExt = {
  readonly analytics: AnalyticsState;
};

type AnalyticsMiddleware = Middleware<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  StateExt,
  Dispatch
>;

/**
 * The names of the supported analytics events.
 */
type AnalyticsEventName = AnalyticsEvent["eventName"];

type FieldEventName = keyof Pick<AnalyticsEvent, "eventName">;
type FieldEventVersion = keyof Pick<AnalyticsEvent, "eventVersion">;
type FieldTime = keyof Pick<AnalyticsEvent, "time">;

type IsWorkingRef = {
  value: boolean;
};

type DequeueEventsPayload = {
  readonly queueIds: readonly string[];
};

/**
 * The payload to enqueue an analytics event.
 *
 * It is a union of the support analytics event types but with the
 * `eventVersion` and `time` fields omitted. These fields will be populated by
 * the case reducer.
 */
type EnqueueEventPayload = {
  [EventName in AnalyticsEventName]: Omit<
    Extract<AnalyticsEvent, Record<FieldEventName, EventName>>,
    FieldEventVersion | FieldTime
  >;
}[AnalyticsEventName];

/**
 * The payload to set the email address captured during the onboarding process
 * or a password reset request.
 */
type SetTemporaryEmailPayload = {
  readonly email: string | null;
};

const initialState: AnalyticsState = {
  enqueuedEvents: [],
  temporaryEmail: null,
  submissions: [],
};

const analyticsSlice = createSlice({
  initialState,
  name: "analytics",
  reducers: {
    dequeueEvents: (state, action: PayloadAction<DequeueEventsPayload>) => {
      state.enqueuedEvents = state.enqueuedEvents.filter(
        (event) => !action.payload.queueIds.includes(event.queueId),
      );
    },
    enqueueEvent: (state, action: PayloadAction<EnqueueEventPayload>) => {
      state.enqueuedEvents.push({
        ...action.payload,
        eventVersion: 0,
        queueId: nanoid(),
        time: Math.round(Date.now() / 1000),
      });
    },
    recordSubmission: (state, action: PayloadAction<EventsSubmission>) => {
      state.submissions.push(action.payload);
      // Only record the last 10 submissions.
      if (state.submissions.length > 10) {
        state.submissions = state.submissions.slice(
          state.submissions.length - 10,
        );
      }
    },
    setTemporaryEmail: (
      state,
      action: PayloadAction<SetTemporaryEmailPayload>,
    ) => {
      state.temporaryEmail = action.payload.email;
    },
  },
});

const {
  actions: { dequeueEvents, enqueueEvent, recordSubmission, setTemporaryEmail },
  name: analyticsReducerPath,
  reducer,
} = analyticsSlice;

async function submitEvents(
  firebaseService: FirebaseService,
  api: MiddlewareAPI<Dispatch, StateExt>,
  enqueuedEvents: readonly EnqueuedEvent[],
): Promise<EventsSubmissionSuccess> {
  const firebaseUser = firebaseService.user;
  const email = firebaseUser
    ? firebaseUser.email
    : api.getState().analytics.temporaryEmail;
  if (!email) throw new Error("Unable to determine user's email address.");

  const authenticatedProps = firebaseUser
    ? {
        token: await getIdToken(firebaseUser),
        userId: firebaseUser.uid,
      }
    : null;

  const eventsSubmission: EventsSubmissionSuccess = {
    events: enqueuedEvents.map(
      ({ queueId, ...event }): SubmittedEvent =>
        analyticsEventConfigMap[event.eventName].requiresUserAuthentication &&
        !firebaseUser
          ? {
              ...event,
              submissionStatus: "failed",
              submissionError: miniSerializeError(
                new Error("Event requires authentication."),
              ),
            }
          : {
              ...event,
              submissionStatus: "success",
            },
    ),
    result: "success",
    time: new Date().toISOString(),
  };

  const events = eventsSubmission.events
    .filter(
      (event): event is SubmittedEventSuccess =>
        event.submissionStatus === "success",
    )
    .map(({ submissionStatus, ...event }) => event);

  const requestPayload: Writable<AnalyticsQueueEventsRequest> = {
    email,
    events,
  };
  if (authenticatedProps) {
    requestPayload.token = authenticatedProps.token;
    requestPayload.userId = authenticatedProps.userId;
  }

  const response = await fetch(
    process.env.GATSBY_ANALYTICS_API_URL.replace(/\/?$/, "") + "/events",
    {
      body: JSON.stringify(requestPayload),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    },
  );
  if (!response.ok) {
    throw new Error(
      `Request failed: ${response.status}: ${response.statusText}`,
    );
  }

  return eventsSubmission;
}

async function workQueue(
  firebaseService: FirebaseService,
  isWorkingRef: IsWorkingRef,
  api: MiddlewareAPI<Dispatch, StateExt>,
) {
  if (isWorkingRef.value) return;
  isWorkingRef.value = true;

  let events = api.getState().analytics.enqueuedEvents;
  while (events.length > 0) {
    api.dispatch(
      dequeueEvents({
        queueIds: events.map((event) => event.queueId),
      }),
    );
    try {
      const submission = await submitEvents(firebaseService, api, events);
      api.dispatch(recordSubmission(submission));
    } catch (error) {
      api.dispatch(
        recordSubmission({
          error: miniSerializeError(error),
          events,
          result: "failed",
          time: Date.now().toLocaleString(),
        }),
      );
    }
    events = api.getState().analytics.enqueuedEvents;
  }
  isWorkingRef.value = false;
}

function handleAction(
  firebaseService: FirebaseService,
  isWorkingRef: IsWorkingRef,
  api: MiddlewareAPI<Dispatch, StateExt>,
  next: Dispatch<AnyAction>,
  action: AnyAction,
): unknown {
  // Record the user's email address from the sign up form. This is so that
  // events submitted before user registration has been completed have some
  // email address attached.
  if (signUpSetFields.match(action)) {
    const currentEmail = api.getState().analytics.temporaryEmail;
    const nextEmail = action.payload.email;
    if (nextEmail !== undefined && currentEmail !== nextEmail) {
      next(setTemporaryEmail({ email: nextEmail }));
    }
  }
  if (signUpClear.match(action)) {
    next(setTemporaryEmail({ email: null }));
  }

  // Use the email address supplied during a password reset request for the
  // "passwordResetRequested" analytics event.
  if (sessionSendPasswordResetEmail.fulfilled.match(action)) {
    const currentEmail = api.getState().analytics.temporaryEmail;
    const nextEmail = action.meta.arg.email;
    if (currentEmail !== nextEmail) {
      next(setTemporaryEmail({ email: nextEmail }));
    }
  }

  // Start the event submission queue worker, if not already started, when an
  // event is enqueued.
  if (enqueueEvent.match(action)) {
    // Process the action on the following tick so that the Redux slice has a
    // chance to commit the action to the queue.
    setTimeout(() => {
      workQueue(firebaseService, isWorkingRef, api);
    });
  }

  return next(action);
}

function handleNext(
  firebaseService: FirebaseService,
  isWorkingRef: IsWorkingRef,
  api: MiddlewareAPI<Dispatch, StateExt>,
  next: Dispatch<AnyAction>,
): ReturnType<ReturnType<AnalyticsMiddleware>> {
  return (action) => {
    return handleAction(firebaseService, isWorkingRef, api, next, action);
  };
}

function middleware(
  firebaseService: FirebaseService,
  isWorkingRef: IsWorkingRef,
  api: MiddlewareAPI<Dispatch, StateExt>,
): ReturnType<AnalyticsMiddleware> {
  return (next) => {
    return handleNext(firebaseService, isWorkingRef, api, next);
  };
}

export function createAnalyticsMiddleware({
  services: { firebaseService },
}: {
  readonly services: StoreServices;
}): AnalyticsMiddleware {
  const isWorkingRef: IsWorkingRef = {
    value: false,
  };

  return (api) => {
    return middleware(firebaseService, isWorkingRef, api);
  };
}

export {
  enqueueEvent as analyticsEnqueueEvent,
  reducer as analyticsReducer,
  analyticsReducerPath,
};
