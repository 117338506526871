import { isValid, parse } from "date-fns";
import type { Either } from "fp-ts/Either";
import * as t from "io-ts";
import type { Opaque } from "type-fest";

import { logger } from "../utils";

export type DateString = Opaque<string, "DateString">;

const formatString = "MM/dd/yyyy";

/**
 * A type predicate which returns `true` if `input` is a `DateString`.
 *
 * Exposed as `dateStringCodec.is()`.
 */
function is(input: unknown): input is DateString {
  if (typeof input !== "string") return false;

  // Treat empty strings as unset since the API uses empty strings in place of
  // null.
  if (input === "") return true;

  try {
    const date = parse(input, formatString, new Date(0));
    return isValid(date);
  } catch (error) {
    logger.warn("Failed to parse date string:", error);
    return false;
  }
}

/**
 * Returns either a success object, with the validated date string, or failure
 * object.
 *
 * Exposed as `dateStringCodec.decode()`.
 */
function validate(
  input: unknown,
  context: t.Context,
): Either<t.Errors, DateString> {
  return is(input) ? t.success(input) : t.failure(input, context);
}

/**
 * Returns the `DateString` as a `string`.
 *
 * Exposed as `dateStringCodec.encode()`.
 */
function encode(input: DateString): string {
  return input;
}

/**
 * Validates that strings are either empty, representing an unset date value, or
 * a date string in the format `MM/DD/YYYY`.
 */
export const dateStringCodec = new t.Type("dateString", is, validate, encode);
