import type { AnyAction } from "@reduxjs/toolkit";

export type ErrorState = Readonly<Record<string, Error | string | null>>;

type ErrorAction = {
  readonly error: Error | string;
  readonly type: string;
};

function isErrorAction(action: AnyAction): action is ErrorAction {
  return "error" in action;
}

export function errorReducer(
  state: ErrorState = {},
  action: AnyAction,
): ErrorState {
  if (!isErrorAction(action)) return state;

  const matches = /(.*)_(REQUEST|FAILURE|CLEAR_ERRORS)/.exec(action.type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  if (!requestName || !requestState) return state;

  return {
    ...state,
    [requestName]: requestState === "FAILURE" ? action.error : null,
  };
}
