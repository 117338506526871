import {
  type CurriculumApiDtoStudent,
  curriculumApiDtoStudentSchema,
} from "../dto/CurriculumApiDtoStudent";

/**
 * The response body of a request to retrieve a particular user.
 */
export type CurriculumApiResponseFindStudentById = CurriculumApiDtoStudent;

export const curriculumApiResponseFindStudentByIdSchema =
  curriculumApiDtoStudentSchema;
