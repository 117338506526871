export async function getBody(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<object | null> {
  // Enforce that the "Content-Type" header was set to "application/json".
  const headers =
    input instanceof Request ? input.headers : new Headers(init?.headers);
  console.log(
    "curriculumApiFetchMock: Headers:",
    Array.from(headers.entries()).reduce((accumulator, [key, value]) => {
      accumulator[key] = value;
      return accumulator;
    }, {} as Record<string, string>),
  );

  const contentTypeHeader = headers.get("Content-Type");
  if (contentTypeHeader !== "application/json") return null;

  const bodyOption = input instanceof Request ? input.body : init?.body;
  if (!bodyOption) return null;

  // In this mock, we only support a body option provided as a string or a
  // ReadableStream (as supplied by Redux Toolkit Query?).
  if (
    typeof bodyOption !== "string" &&
    !(bodyOption instanceof ReadableStream)
  ) {
    throw new Error(
      `Body option type not implemented: ${
        typeof bodyOption === "object"
          ? Object.getPrototypeOf(bodyOption)
          : typeof bodyOption
      }`,
    );
  }

  let bodyParsed: unknown;

  if (bodyOption instanceof ReadableStream) {
    const reader = bodyOption.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      if (value) buffer += decoder.decode(value);
    }

    bodyParsed = JSON.parse(buffer);
  } else {
    bodyParsed = JSON.parse(bodyOption);
  }

  if (Array.isArray(bodyParsed)) return bodyParsed;
  if (typeof bodyParsed === "object" && bodyParsed !== null) return bodyParsed;

  throw new Error("Only object and array payloads are currently implemented.");
}
