import * as z from "zod";

import {
  type CurriculumClassType,
  type CurriculumStudentId,
  curriculumClassTypeSchema,
  curriculumStudentIdSchema,
} from "../types";

/**
 * A newly created student instance, associated with a Swap platform user. A
 * Swap user can have many student instances, but only one per
 * `CurriculumClassType`.
 */
export type CurriculumApiDtoNewStudent = {
  /**
   * The new student's ID.
   */
  readonly student_id: CurriculumStudentId;

  /**
   * The type of class the student is enrolled in.
   */
  readonly student_type: CurriculumClassType;
};

/**
 * Validation schema for `CurriculumApiDtoNewStudent`.
 *
 * @see {CurriculumApiDtoNewStudent}
 */
export const curriculumApiDtoNewStudentSchema = z
  .object({
    student_id: curriculumStudentIdSchema,
    student_type: curriculumClassTypeSchema,
  })
  .transform((arg): CurriculumApiDtoNewStudent => arg);
