import type * as z from "zod";

import { createStringifiedSchema } from "./createStringifiedSchema";
import { curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsSchema } from "./CurriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbs";

export type CurriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringified =
  z.infer<
    typeof curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema
  >;

export const curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema =
  createStringifiedSchema<"CurriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringified">(
    curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsSchema,
  );
