import type { ApiArgs } from "./ApiArgs";

/**
 * Returns the request method for the operation.
 *
 * @param args The request arguments.
 * @returns The request method.
 */
export function getRequestMethod(args: ApiArgs): "GET" | "PATCH" | "POST" {
  switch (args.operation) {
    case "userCashbackGet":
    case "userGet": {
      return "GET";
    }

    case "userCashbackEventCreate":
    case "userCreate": {
      return "POST";
    }

    case "userUpdate": {
      return "PATCH";
    }
  }
}
