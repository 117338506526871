import { miniSerializeError } from "@reduxjs/toolkit";
import { getIdToken } from "firebase/auth";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";

import type { ApiBaseQueryFn } from "./ApiBaseQueryFn";
import type { ApiError } from "./ApiError";
import { getApiErrorFromResponse } from "./getApiErrorFromResponse";
import { getRequestMethod } from "./getRequestMethod";
import { getRequestUrl } from "./getRequestUrl";
import { getResponseCodec } from "./getResponseCodec";
import { isStoreServices } from "./isStoreServices";

/**
 * The base query used by each endpoint of the API.
 *
 * It wraps `fetch`, and handles both attaching the current user's session token
 * and validating the shape of responses.
 *
 * Additionally, it returns normalized error types.
 */
export const apiBaseQuery: ApiBaseQueryFn = async (args, api) => {
  try {
    const services = api.extra;
    if (!isStoreServices(services)) {
      throw new Error("Expected StoreServices to be injected.");
    }

    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };

    // Retrieve the ID Token for the user, if they are authenticated, and attach
    // it as a header.
    const firebaseUser = services.firebaseService.user;
    if (firebaseUser) {
      const idTokenJwt = await getIdToken(firebaseUser);
      headers["Authorization"] = `Bearer ${idTokenJwt}`;
    }

    const requestInit: RequestInit = {
      headers,
      method: getRequestMethod(args),
      signal: api.signal,
    };

    // If the request contains a body, attach it to the request.
    if ("body" in args) {
      const body = JSON.stringify(args.body);
      requestInit.body = body;
    }

    const urlOrError = getRequestUrl(args, firebaseUser);
    if (typeof urlOrError !== "string") {
      return {
        error: urlOrError,
        meta: { args },
      };
    }

    const response = await fetch(urlOrError, requestInit);

    // Return an error indicating that a response code fell outside of the 2xx
    // range and attach relevant details.
    if (!response.ok) {
      return {
        error: await getApiErrorFromResponse(response),
        meta: { args },
      };
    }

    let responseBody: unknown;
    try {
      responseBody = await response.json();
    } catch {
      // If the response is not JSON, it is probably a response without a body.
    }

    // Determine the response body validation schema to use.
    const codec = getResponseCodec(args);

    // If the response body is not expected, return an empty response.
    if (codec === t.void) {
      return {
        data: undefined,
        meta: { args },
      };
    }

    // Validate the response body against the schema.
    const decodeResult = codec.decode(responseBody);
    if (isLeft(decodeResult)) {
      // Return an error indicating that an error occurred while validating the
      // response body.
      const report = PathReporter.report(decodeResult);
      const apiError: ApiError = {
        kind: "errorValidation",
        report,
      };
      return {
        error: apiError,
        meta: { args },
      };
    }

    return {
      data: decodeResult.right,
      meta: { args },
    };
  } catch (error) {
    const apiError: ApiError = {
      ...miniSerializeError(error),
      kind: "errorGeneral",
    };
    return {
      error: apiError,
      meta: { args },
    };
  }
};
