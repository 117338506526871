import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import type { Except } from "type-fest";

import type { ApiUserCreateBody } from "../api";
import type { DateString } from "../codecs";

/**
 * Fields which are collected during various steps of the sign up process and
 * are used to create a new user.
 */
export type SignUpState = Except<
  {
    [P in keyof ApiUserCreateBody]: ApiUserCreateBody[P] | null;
  },
  "user_id"
> & {
  readonly password: string | null;
};

export type SetFieldsOptions = {
  [P in keyof SignUpState]?: NonNullable<SignUpState[P]>;
};

const initialState: SignUpState = {
  address: null,
  address2: null,
  city: null,
  current_a1c: null,
  ebt_monthly_benefit: null,
  email: null,
  first_name: null,
  has_ebt: null,
  is_diabetic: null,
  last_a1c_measurement: format(new Date(), "MM/dd/yyyy") as DateString,
  last_name: null,
  password: null,
  phone: null,
  state: null,
  zip_code: null,
};

const signUpSlice = createSlice({
  initialState,
  name: "signUp",
  reducers: {
    clear: () => {
      return initialState;
    },
    setFields: (state, action: PayloadAction<SetFieldsOptions>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  actions: { clear: signUpClear, setFields: signUpSetFields },
  name: signUpReducerPath,
  reducer: signUpReducer,
} = signUpSlice;
