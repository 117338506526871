import type { Opaque } from "type-fest";
import * as z from "zod";

/**
 * The ID of a Swap platform user.
 */
export type CurriculumSwapUserId = z.infer<typeof curriculumSwapUserIdSchema>;

/**
 * The validation schema for `CurriculumSwapUserId`.
 *
 * @see {CurriculumSwapUserId}
 */
export const curriculumSwapUserIdSchema = z
  .string()
  .trim()
  .min(1)
  .transform((arg) => arg as Opaque<string, "CurriculumSwapUserId">);
