import { Alert as ReachAlert } from "@reach/alert";
import { animated, config, useTransition } from "@react-spring/web";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { Except } from "type-fest";

import { AlertIcon } from "./AlertIcon";

type Alert = {
  readonly id: number;
  readonly level: "error" | "success" | "warning";
  readonly message: string;
};

export type AddAlertOptions = Except<Alert, "id">;

export type AddAlertFn = (alert: AddAlertOptions) => void;

type AlertContextValue = {
  readonly addAlert: AddAlertFn;
};

export type AlertProviderProps = {
  readonly children?: ReactNode;
};

const AlertContext = createContext<AlertContextValue | null>(null);

const AnimatedAlert = animated(ReachAlert);

export function AlertProvider({ children }: AlertProviderProps) {
  const [alerts, setAlerts] = useState<readonly Alert[]>([]);

  const addAlert = useCallback<AddAlertFn>((alert) => {
    const id = Date.now();

    setAlerts((previousAlerts) => [...previousAlerts, { ...alert, id }]);

    setTimeout(() => {
      setAlerts((previousAlerts) =>
        previousAlerts.filter((alert) => alert.id !== id),
      );
    }, 5000);
  }, []);

  const value = useMemo(
    (): AlertContextValue => ({
      addAlert,
    }),
    [addAlert],
  );

  const transitions = useTransition(alerts, {
    config: config.wobbly,
    delay: 0,
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  });

  return (
    <AlertContext.Provider value={value}>
      {children}

      <div className="flex flex-col items-center fixed p-2 left-0 top-0 w-full z-20 pointer-events-none space-y-2">
        {transitions(({ opacity }, alert) => (
          <AnimatedAlert
            className="flex items-center px-4 py-2 text-sm bg-white rounded-sm shadow-xl"
            key={alert.id}
            style={{ opacity }}
          >
            <AlertIcon className="mr-2" level={alert.level} />
            <span>{alert.message}</span>
          </AnimatedAlert>
        ))}
      </div>
    </AlertContext.Provider>
  );
}

export function useAddAlert() {
  const value = useContext(AlertContext);
  if (!value) {
    throw new Error("useAddAlert must be used within an AlertProvider.");
  }
  return value.addAlert;
}
