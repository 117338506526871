import { parseISO } from "date-fns";

import type { CurriculumApiDtoLesson } from "./dto/CurriculumApiDtoLesson";
import { normalizeCurriculumDtoLesson } from "./normalizeCurriculumDtoLesson";
import type { CurriculumLesson } from "./types";

export function normalizeCurriculumDtoLessons(
  lessons: readonly CurriculumApiDtoLesson[],
): readonly CurriculumLesson[] {
  return lessons
    .map(normalizeCurriculumDtoLesson)
    .filter((lesson): lesson is CurriculumLesson => lesson !== null)
    .filter((lesson) => lesson.classType === "dpp")
    .sort(
      (a, b) =>
        parseISO(a.dateAvailable).getTime() -
        parseISO(b.dateAvailable).getTime(),
    );
}
