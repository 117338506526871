import * as t from "io-ts";

/**
 * The response to a user creation request.
 *
 * For this request, it is treated as an empty response because the API doesn't
 * return any useful information.
 */
export type ApiUserCreateResult = t.TypeOf<typeof apiUserCreateResultCodec>;

export const apiUserCreateResultCodec = t.void;
