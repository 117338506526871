import * as t from "io-ts";

import {
  dateStringCodec,
  lowercaseTrimmedStringCodec,
  rfc3339StringCodec,
  trimmedStringCodec,
} from "../codecs";

/**
 * An API user record.
 */
export type ApiUser = t.TypeOf<typeof apiUserCodec>;

/**
 * Validates and normalizes the user record returned by the API.
 */
export const apiUserCodec = t.readonly(
  t.intersection([
    t.type({
      address: trimmedStringCodec,

      address2: trimmedStringCodec,

      city: trimmedStringCodec,

      /**
       * A date string in the format MM/DD/YYYY.
       *
       * @example "01/30/1970"
       *
       * @deprecated This does not appear to be used.
       */
      dob: dateStringCodec,

      /**
       * When the user account was created.
       *
       * A date-time string in the notation defined by
       * [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
       * or `null` if no timestamp was recorded at the time of creation.
       *
       * @example "2022-04-12T23:20:50.52Z"
       */
      created_at: t.union([rfc3339StringCodec, t.null]),

      current_a1c: t.number,

      current_cashback: t.number,

      ebt_monthly_benefit: t.number,

      email: lowercaseTrimmedStringCodec,

      /**
       * Whether or not the extension is enabled on the basis that a user has met
       * some study criteria.
       *
       * A boolean.
       *
       * @example true
       */
      extension_enabled: t.boolean,

      first_name: trimmedStringCodec,

      /**
       * When the user first completed an Amazon Fresh order.
       *
       * A date-time string in the notation defined by
       * [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
       * or `null` if no order has been placed.
       *
       * @example "2022-04-12T23:20:50.52Z"
       */
      first_order_placed_at: t.union([rfc3339StringCodec, t.null]),

      has_ebt: t.boolean,

      has_viewed_tutorial: t.boolean,

      is_diabetic: t.boolean,

      /**
       * A date string in the format MM/DD/YYYY.
       *
       * @example "01/30/1970"
       */
      last_a1c_measurement: dateStringCodec,

      last_name: trimmedStringCodec,

      phone: trimmedStringCodec,

      state: trimmedStringCodec,

      total_cashback: t.number,

      user_id: t.string,

      user_status: t.keyof({
        "": null,
        ACTIVE: null,
        DELETED: null,
        INACTIVE: null,
        INELIGIBLE: null,
      }),

      /**
       * @deprecated This does not appear to be used.
       */
      username: trimmedStringCodec,

      zip_code: trimmedStringCodec,
    }),
    // TODO: Add this to the backend API response. Currently, this is being
    // simulated in the frontend.
    t.partial({
      /**
       * Whether or not the user has opted in to the curriculum feature.
       */
      feature_curriculum: t.boolean,
    }),
  ]),
);
