exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-verified-tsx": () => import("./../../../src/pages/account-verified.tsx" /* webpackChunkName: "component---src-pages-account-verified-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-curriculum-dpp-class-1-tsx": () => import("./../../../src/pages/curriculum/dpp/class1.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-1-tsx" */),
  "component---src-pages-curriculum-dpp-class-2-tsx": () => import("./../../../src/pages/curriculum/dpp/class2.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-2-tsx" */),
  "component---src-pages-curriculum-dpp-class-3-tsx": () => import("./../../../src/pages/curriculum/dpp/class3.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-3-tsx" */),
  "component---src-pages-curriculum-dpp-class-4-tsx": () => import("./../../../src/pages/curriculum/dpp/class4.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-4-tsx" */),
  "component---src-pages-curriculum-dpp-class-5-tsx": () => import("./../../../src/pages/curriculum/dpp/class5.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-5-tsx" */),
  "component---src-pages-curriculum-dpp-class-6-tsx": () => import("./../../../src/pages/curriculum/dpp/class6.tsx" /* webpackChunkName: "component---src-pages-curriculum-dpp-class-6-tsx" */),
  "component---src-pages-curriculum-index-tsx": () => import("./../../../src/pages/curriculum/index.tsx" /* webpackChunkName: "component---src-pages-curriculum-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-forget-password-tsx": () => import("./../../../src/pages/forget-password.tsx" /* webpackChunkName: "component---src-pages-forget-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-rewards-tsx": () => import("./../../../src/pages/rewards.tsx" /* webpackChunkName: "component---src-pages-rewards-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-tutorial-tsx": () => import("./../../../src/pages/tutorial.tsx" /* webpackChunkName: "component---src-pages-tutorial-tsx" */),
  "component---src-pages-update-profile-tsx": () => import("./../../../src/pages/update-profile.tsx" /* webpackChunkName: "component---src-pages-update-profile-tsx" */)
}

