import * as z from "zod";

import {
  curriculumClassTypeSchema,
  curriculumDateSchema,
  curriculumStudentIdSchema,
  curriculumSwapUserIdSchema,
} from "../types";

/**
 * A student instance associated with a Swap platform user. A Swap user can have
 * many student instances, but only one per `CurriculumClassType`.
 */
export type CurriculumApiDtoStudent = z.infer<
  typeof curriculumApiDtoStudentSchema
>;

/**
 * The validation schema for `CurriculumApiDtoStudent`.
 *
 * @see {CurriculumApiDtoStudent}
 */
export const curriculumApiDtoStudentSchema = z.object({
  created: curriculumDateSchema,
  id: curriculumStudentIdSchema,
  student_type: curriculumClassTypeSchema,
  swap_user_id: curriculumSwapUserIdSchema,
});
