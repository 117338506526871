import type * as z from "zod";

import { curriculumApiDtoStudentSchema } from "./CurriculumApiDtoStudent";

/**
 * The API `Student` model without the `created` field. The `created` field is
 * populated automatically by the API.
 */
export type CurriculumApiDtoStudentCreate = z.infer<
  typeof curriculumApiDtoStudentCreateSchema
>;

export const curriculumApiDtoStudentCreateSchema =
  curriculumApiDtoStudentSchema.omit({
    created: true,
  });
