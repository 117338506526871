import type { SetNonNullable, SetRequired } from "type-fest";

import type { CurriculumApiDtoLesson } from "./dto/CurriculumApiDtoLesson";
import type { CurriculumLesson } from "./types";

type RequiredKeys = keyof typeof requiredKeysMap;

const requiredKeysMap: Record<
  keyof Pick<
    CurriculumApiDtoLesson,
    "class_type" | "id" | "lesson_available_date"
  >,
  true
> = {
  class_type: true,
  id: true,
  lesson_available_date: true,
};

const requiredKeys = Object.keys(
  requiredKeysMap,
) as (keyof typeof requiredKeysMap)[];

function isLessonDtoWithRequiredKeys(
  lessonDto: CurriculumApiDtoLesson,
): lessonDto is SetNonNullable<
  SetRequired<CurriculumApiDtoLesson, RequiredKeys>,
  RequiredKeys
> {
  return requiredKeys.every(
    (key) => lessonDto[key] !== null && lessonDto[key] !== undefined,
  );
}

export function normalizeCurriculumDtoLesson(
  lesson: CurriculumApiDtoLesson,
): CurriculumLesson | null {
  if (!isLessonDtoWithRequiredKeys(lesson)) {
    return null;
  }

  return {
    classType: lesson.class_type,
    data: lesson.data || null,
    dateAvailable: lesson.lesson_available_date,
    dateComplete: lesson.complete_date || null,
    dateStart: lesson.start_date || null,
    description: lesson.lesson_details?.description || "",
    headerImageUrl: lesson.lesson_details?.header_image_url || "",
    lessonId: lesson.id,
    progress: lesson.progress
      ? {
          currentPage: lesson.progress.current_page || 0,
          currentSection: lesson.progress.current_section || 0,
        }
      : {
          currentPage: 0,
          currentSection: 0,
        },
    reportedWeeklyActivityInMinutes:
      lesson.reported_weekly_activity_in_minutes || null,
    reportedWeeklyBodyWeightInLbs:
      lesson.reported_weekly_body_weight_in_lbs || null,
    title: lesson.lesson_details?.title || "",
  };
}
