import type { JsonValue, Opaque } from "type-fest";
import * as z from "zod";

export function createStringifiedSchema<Token extends string = never>(
  jsonParsedSchema: z.ZodSchema,
) {
  return z
    .string()
    .superRefine((arg, ctx) => {
      let argJson: JsonValue;

      try {
        argJson = JSON.parse(arg);
      } catch (error) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          message: `Value is not a stringified JSON field: ${error}`,
        });
        return;
      }

      const result = jsonParsedSchema.safeParse(argJson);
      if (result.success) return;

      result.error.issues.forEach((issue) => {
        ctx.addIssue({
          ...issue,
          path: [...ctx.path, ...issue.path],
        });
      });
    })
    .transform((arg) => arg as Opaque<string, Token>);
}
