// @ts-check
import "@reach/dialog/styles.css";
import "antd/dist/antd.css";
import "./src/theme/index.css";

import React from "react";
import { Provider } from "react-redux";

import { AlertProvider } from "./src/alert";
import ErrorBoundary from "./src/components/ErrorBoundary";
import { configureStore, initializeServices } from "./src/store";

/**
 * @param {{element: React.ReactNode}} param0
 */
export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const services = initializeServices();
  const store = configureStore({ services });

  return (
    <Provider store={store}>
      <AlertProvider>
        <ErrorBoundary>{element}</ErrorBoundary>
      </AlertProvider>
    </Provider>
  );
};
