import { clsx } from "clsx";
import { MdCheckCircle, MdClose, MdError } from "react-icons/md";

export type AlertIconProps = {
  readonly className?: string;
  readonly svgClassName?: string;
  readonly level: "error" | "success" | "warning";
};

export function AlertIcon({
  className: classNameProp,
  svgClassName: svgClassNameProp,
  level,
}: AlertIconProps) {
  const className = clsx(
    "block w-4 h-4 rounded-full",
    level === "error" && "p-[0.125rem] bg-red-400",
    classNameProp,
  );

  const SvgIcon =
    level === "error" ? MdClose : level === "success" ? MdCheckCircle : MdError;

  const svgClassName = clsx(
    "w-full h-full",
    level === "error" && "fill-white",
    level === "success" && "fill-green-500",
    level === "warning" && "fill-orange-500",
    svgClassNameProp,
  );

  return (
    <span className={className}>
      <SvgIcon className={svgClassName} />
    </span>
  );
}
