import { createApi } from "@reduxjs/toolkit/query/react";

import { apiBaseQuery } from "./apiBaseQuery";
import type { ApiUser } from "./ApiUser";
import type { ApiUserCashbackEventCreateResult } from "./ApiUserCashbackEventCreateResult";
import type { ApiUserCashbackGetResult } from "./ApiUserCashbackGetResult";
import type { ApiUserCreateArgs } from "./ApiUserCreateArgs";
import type { ApiUserCreateResult } from "./ApiUserCreateResult";
import type { ApiUserGetResult } from "./ApiUserGetResult";
import type { ApiUserUpdateArgs } from "./ApiUserUpdateArgs";
import type { ApiUserUpdateResult } from "./ApiUserUpdateResult";

const api = createApi({
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    userCashbackEventCreate: builder.mutation<
      ApiUserCashbackEventCreateResult,
      void
    >({
      invalidatesTags: ["cashback"],
      query: () => ({
        operation: "userCashbackEventCreate",
      }),
      transformResponse: () => {
        return;
      },
    }),
    userCashbackGet: builder.query<ApiUserCashbackGetResult, void>({
      providesTags: ["cashback"],
      query: () => ({
        operation: "userCashbackGet",
      }),
      transformResponse: (response: ApiUserCashbackGetResult) => {
        return response;
      },
    }),
    userCreate: builder.mutation<
      ApiUserCreateResult,
      Pick<ApiUserCreateArgs, "body">
    >({
      invalidatesTags: ["user"],
      query: ({ body }) => ({
        body,
        operation: "userCreate",
      }),
      transformResponse: () => {
        return;
      },
    }),
    userGet: builder.query<ApiUser, void>({
      providesTags: ["user"],
      query: () => ({
        operation: "userGet",
      }),
      transformResponse: (response: ApiUserGetResult) => {
        // TODO: Retrieve this from the backend. Currently this is being
        // simulated in the frontend.
        if (response.feature_curriculum !== undefined) return response;
        return {
          ...response,
          feature_curriculum: process.env.NODE_ENV === "development",
        };
      },
    }),
    userUpdate: builder.mutation<
      ApiUserUpdateResult,
      Pick<ApiUserUpdateArgs, "body">
    >({
      invalidatesTags: ["user"],
      query: ({ body }) => ({
        body,
        operation: "userUpdate",
      }),
      transformResponse: () => {
        return;
      },
    }),
  }),
  reducerPath: "api",
  tagTypes: ["cashback", "user"],
});

export const {
  middleware: apiMiddleware,
  reducer: apiReducer,
  reducerPath: apiReducerPath,
  useUserCashbackEventCreateMutation,
  useUserCashbackGetQuery,
  useUserCreateMutation,
  useUserGetQuery,
  useUserUpdateMutation,
  util: { resetApiState },
} = api;
