import { addWeeks, formatRFC3339 } from "date-fns";

import {
  type CurriculumClassType,
  curriculumDateSchema,
  curriculumLessonIdSchema,
} from "../api";
import type { CurriculumApiDtoLesson } from "../api/dto/CurriculumApiDtoLesson";

export function createCurriculumDummyLessonsDto(
  studentType: CurriculumClassType,
): readonly CurriculumApiDtoLesson[] {
  return Array.from(
    {
      length: 6,
    },
    (_, index): CurriculumApiDtoLesson => ({
      class_type: studentType,
      id: curriculumLessonIdSchema.parse(`class${index + 1}`),
      lesson_available_date: curriculumDateSchema.parse(
        formatRFC3339(addWeeks(new Date(), index)),
      ),
    }),
  );
}
