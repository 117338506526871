import type * as z from "zod";

import { createStringifiedSchema } from "./createStringifiedSchema";
import { curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesSchema } from "./CurriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutes";

export type CurriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringified =
  z.infer<
    typeof curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema
  >;

export const curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema =
  createStringifiedSchema<"CurriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringified">(
    curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesSchema,
  );
