import type * as z from "zod";

import { createStringifiedSchema } from "./createStringifiedSchema";
import { curriculumApiDtoLessonPatch_ProgressSchema } from "./CurriculumApiDtoLessonPatch_Progress";

/**
 * The JSON stringified version of `CurriculumApiDtoLessonProgressPatch`.
 */
export type CurriculumApiDtoLessonPatch_ProgressStringified = z.infer<
  typeof curriculumApiDtoLessonPatch_ProgressStringifiedSchema
>;

export const curriculumApiDtoLessonPatch_ProgressStringifiedSchema =
  createStringifiedSchema<"CurriculumApiDtoLessonPatch_ProgressStringified">(
    curriculumApiDtoLessonPatch_ProgressSchema,
  );
