import type { ApiError } from "./ApiError";

/**
 * A type predicate which returns `true` if the provided `value` contains a
 * `code` field which is a _number_.
 */
function isCodeObject(value: unknown): value is { readonly code: number } {
  return (
    typeof value === "object" &&
    value !== null &&
    "code" in value &&
    typeof (value as { readonly code: unknown }).code === "number"
  );
}

/**
 * A type predicate which returns `true` if the provided `value` contains a
 * `message` field which is a _string_.
 */
function isMessageObject(
  value: unknown,
): value is { readonly message: string } {
  return (
    typeof value === "object" &&
    value !== null &&
    "message" in value &&
    typeof (value as { readonly message: unknown }).message === "string"
  );
}

/**
 * Returns an `ApiError` using the provided `Response` object.
 */
export async function getApiErrorFromResponse(
  response: Response,
): Promise<ApiError> {
  let code: number | null = null;
  let message: string | null = null;
  const parseWarnings: string[] = [];

  try {
    const responseBody: unknown = await response.json();

    if (isCodeObject(responseBody)) code = responseBody.code;
    else parseWarnings.push("Unable to parse error code.");

    if (isMessageObject(responseBody)) message = responseBody.message;
    else parseWarnings.push("Unable to parse error message.");
  } catch {
    parseWarnings.push("Unable to parse error body.");
  }

  return {
    code,
    kind: "errorResponse",
    message,
    parseWarnings,
    status: response.status,
    statusText: response.statusText,
  };
}
