import type * as z from "zod";

import { curriculumApiDtoLessonSchema } from "../dto/CurriculumApiDtoLesson";

export type CurriculumApiResponsePatchStudentLessonById = z.infer<
  typeof curriculumApiResponsePatchStudentLessonByIdSchema
>;

export const curriculumApiResponsePatchStudentLessonByIdSchema =
  curriculumApiDtoLessonSchema;
