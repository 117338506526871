import { curriculumApiBaseUrl } from "../curriculumApiBaseUrl";

/**
 * Returns the relative request path of the provided URL. The relative request
 * path is the path without the base URL.
 *
 * An error is thrown if the request path does not start with the base URL or if
 * it contains a superfluous starting slash.
 *
 * @param input The first parameter of a `fetch` call.
 * @returns The relative request path.
 */
export function getRelativePath(input: RequestInfo | URL): string {
  const url =
    typeof input === "string" ? input : "url" in input ? input.url : input.href;
  const regex = new RegExp(`^${curriculumApiBaseUrl}(/[^/].+)`);
  const relativeRequestPath = url.match(regex)?.[1];
  if (!relativeRequestPath) throw new Error("Unable to connect to server.");
  return relativeRequestPath;
}
