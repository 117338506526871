import {
  type CurriculumApiDtoNewStudent,
  curriculumApiDtoNewStudentSchema,
} from "../dto/CurriculumApiDtoNewStudent";

/**
 * The response body of a request to create a new user.
 */
export type CurriculumApiResponseAddStudent = CurriculumApiDtoNewStudent;

export const curriculumApiResponseAddStudentSchema =
  curriculumApiDtoNewStudentSchema;
