import * as z from "zod";

import { curriculumApiDtoLessonSchema } from "../dto/CurriculumApiDtoLesson";

export type CurriculumApiResponseGetStudentLessons = z.infer<
  typeof curriculumApiResponseGetStudentLessonsSchema
>;

export const curriculumApiResponseGetStudentLessonsSchema = z.array(
  curriculumApiDtoLessonSchema,
);
