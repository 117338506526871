import { isValid, parseISO } from "date-fns";
import type { Opaque } from "type-fest";
import * as z from "zod";

/**
 * A RFC 3339 formatted date.
 *
 * @example
 * "2017-07-21"
 */
export type CurriculumDate = z.infer<typeof curriculumDateSchema>;

/**
 * The validation schema for `CurriculumDate`.
 *
 * @see {CurriculumDate}
 */
export const curriculumDateSchema = z
  .string()
  .trim()
  .refine((arg) => {
    try {
      const date = parseISO(arg);
      return isValid(date);
    } catch (error) {
      console.warn("Failed to parse RFC 3339 date:", error);
      return false;
    }
  })
  .transform((arg) => arg as Opaque<string, "CurriculumDate">);
