import type { Either } from "fp-ts/Either";
import * as t from "io-ts";
import type { Opaque } from "type-fest";

export type LowercaseTrimmedString = Opaque<string, "LowercaseTrimmedString">;

/**
 * A type predicate which returns `true` if `input` is a
 * `LowercaseTrimmedString`.
 *
 * Exposed as `lowercaseTrimmedStringCodec.is()`.
 */
function is(input: unknown): input is LowercaseTrimmedString {
  return typeof input === "string" && input === input.trim().toLowerCase();
}

/**
 * Returns either a success object, with a trimmed string, or failure object.
 *
 * Exposed as `lowercaseTrimmedStringCodec.decode()`.
 */
function validate(
  input: unknown,
  context: t.Context,
): Either<t.Errors, LowercaseTrimmedString> {
  return typeof input === "string"
    ? t.success(input.trim().toLowerCase() as LowercaseTrimmedString)
    : t.failure(input, context);
}

/**
 * Returns the `LowercaseTrimmedString` as a `string`.
 *
 * Exposed as `lowercaseTrimmedStringCodec.encode()`.
 */
function encode(input: LowercaseTrimmedString): string {
  return input;
}

/**
 * Validates that the input is a string and returns it lowercased and trimmed.
 */
export const lowercaseTrimmedStringCodec = new t.Type(
  "lowercaseTrimmedString",
  is,
  validate,
  encode,
);
