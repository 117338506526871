import * as Sentry from "@sentry/gatsby";
import type { ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div>{componentStack}</div>
        <button
          type="button"
          onClick={() => {
            resetError();
          }}
        >
          Click here to reset!
        </button>
      </>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
