import * as z from "zod";

import { curriculumDateSchema } from "../types";
import { curriculumApiDtoLessonPatch_ProgressStringifiedSchema } from "./CurriculumApiDtoLessonPatch_ProgressStringified";
import { curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema } from "./CurriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringified";
import { curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema } from "./CurriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringified";

export type CurriculumApiDtoLessonPatch = z.infer<
  typeof curriculumApiDtoLessonPatchSchema
>;

export const curriculumApiDtoLessonPatchSchema = z.union([
  z.object({
    key: z.literal("CompletedDate"),
    value: curriculumDateSchema,
  }),
  z.object({
    key: z.literal("Progress"),
    value: curriculumApiDtoLessonPatch_ProgressStringifiedSchema,
  }),
  z.object({
    key: z.literal("ReportedWeeklyActivityInMinutes"),
    value:
      curriculumApiDtoLessonPatch_ReportedWeeklyActivityInMinutesStringifiedSchema,
  }),
  z.object({
    key: z.literal("ReportedWeeklyBodyWeightInLbs"),
    value:
      curriculumApiDtoLessonPatch_ReportedWeeklyBodyWeightInLbsStringifiedSchema,
  }),
  z.object({
    key: z.literal("StartDate"),
    value: curriculumDateSchema,
  }),
]);
