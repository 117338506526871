import * as z from "zod";

import { curriculumClassTypeSchema, curriculumDateSchema } from "../types";
import { curriculumLessonIdSchema } from "../types/CurriculumLessonId";
import { curriculumApiDtoLessonProgressSchema } from "./CurriculumApiDtoLessonProgress";

/**
 * A lesson available to a user.
 */
export type CurriculumApiDtoLesson = z.infer<
  typeof curriculumApiDtoLessonSchema
>;

/**
 * The validation schema for `CurriculumApiDtoLesson`.
 *
 * @see {CurriculumApiDtoLesson}
 */
export const curriculumApiDtoLessonSchema = z.object({
  class_type: curriculumClassTypeSchema.nullable().optional(),
  complete_date: curriculumDateSchema.nullable().optional(),
  data: z.object({}).passthrough().nullable().optional(),
  id: curriculumLessonIdSchema.nullable().optional(),
  lesson_available_date: curriculumDateSchema.nullable().optional(),
  lesson_details: z
    .object({
      description: z.string().nullable().optional(),
      header_image_url: z.string().nullable().optional(),
      title: z.string().nullable().optional(),
    })
    .nullable()
    .optional(),
  progress: curriculumApiDtoLessonProgressSchema.nullable().optional(),
  reported_weekly_activity_in_minutes: z.number().int().nullable().optional(),
  reported_weekly_body_weight_in_lbs: z.number().nullable().optional(),
  start_date: curriculumDateSchema.nullable().optional(),
});
