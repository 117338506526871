import { type FirebaseOptions, initializeApp } from "firebase/app";
import type { User } from "firebase/auth";

export class FirebaseService {
  private readonly firebaseApp;

  /**
   * The currently authenticated user, or `null`.
   *
   * This field is set by the `session` middleware and `session` async action
   * creators.
   */
  user: User | null = null;

  constructor() {
    const config: FirebaseOptions = {
      apiKey: process.env.GATSBY_API_KEY,
      authDomain: process.env.GATSBY_AUTH_DOMAIN,
      projectId: process.env.GATSBY_PROJECT_ID,
      storageBucket: process.env.GATSBY_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_APP_ID,
    };
    this.firebaseApp = initializeApp(config);
  }

  /**
   * Returns the initialized Firebase app.
   */
  getApp() {
    return this.firebaseApp;
  }
}
