/**
 * Returns the request method, defaulting to `GET` if not supplied.
 *
 * @param input The first parameter of a `fetch` call.
 * @param init The optional second parameter of a `fetch` call.
 * @returns The request method, defaulting to `GET`.
 */
export function getMethod(
  input: RequestInfo | URL,
  init?: RequestInit,
): string {
  const method =
    typeof input === "string" || "href" in input
      ? init?.method || "GET"
      : input.method;
  return method;
}
