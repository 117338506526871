import type { User } from "firebase/auth";

import type { ApiArgs } from "./ApiArgs";
import type { ApiError } from "./ApiError";

/**
 * The base URL without a forward slash at the end.
 */
const baseUrl = `${process.env.GATSBY_API_SERVER_URL.replace(/\/?$/, "")}`;

/**
 * Returns the URL for the operation.
 *
 * @param args The request arguments.
 * @param firebaseUser The currently authenticated user, or `null`.
 * @returns The request URL, or an `ApiError`.
 */
export function getRequestUrl(
  args: ApiArgs,
  firebaseUser: User | null,
): ApiError | string {
  switch (args.operation) {
    case "userCashbackEventCreate":
    case "userCashbackGet": {
      if (!firebaseUser) {
        return {
          kind: "errorUnauthenticated",
        };
      }
      return `${baseUrl}/user/${firebaseUser.uid}/cashout`;
    }

    case "userCreate": {
      return `${baseUrl}/user`;
    }

    case "userGet":
    case "userUpdate": {
      if (!firebaseUser) {
        return {
          kind: "errorUnauthenticated",
        };
      }
      return `${baseUrl}/user/${firebaseUser.uid}`;
    }
  }
}
