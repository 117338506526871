import {
  type SerializedError,
  createAction,
  createAsyncThunk,
  createSlice,
  miniSerializeError,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  UserCredential,
  verifyPasswordResetCode,
} from "firebase/auth";

import type { RootState, StoreServices } from "../store";
import { addRequestCaseReducers } from "./addRequestCaseReducers";
import { createSessionUserFromFirebaseUser } from "./createSessionUserFromFirebaseUser";
import type { SessionRequestState } from "./SessionRequestState";
import type { SessionUser } from "./SessionUser";

export type SessionState = {
  /**
   * The current's user authentication status.
   */
  readonly current: SessionRequestState<
    {
      /**
       * The user if authenticated, otherwise `null`.
       */
      readonly user: SessionUser | null;
    },
    // Don't add a "currentRequestId" field to this state type because this one
    // is computed from other requests. We don't need to limit it to a single
    // async operation.
    false
  >;

  /**
   * Availability of email addresses for use in sign ups.
   */
  readonly checkEmailAvailability: SessionRequestState<{
    /**
     * The email address whose availability was last checked.
     */
    readonly email: string;

    /**
     * The availability of the email address which was last checked.
     */
    readonly isAvailable: boolean;
  }> & {
    /**
     * The availability of email addresses which have already been checked.
     */
    readonly byEmail: Record<string, boolean>;
  };

  /**
   * Sending of an email verification operation request state.
   */
  readonly sendEmailVerification: SessionRequestState;

  /**
   * Sending of a password reset email operation request state.
   */
  readonly sendPasswordResetEmail: SessionRequestState;

  /**
   * Sign in operation request state.
   */
  readonly signIn: SessionRequestState;

  /**
   * Sign in operation request state using Facebook.
   */
  readonly signInWithFacebook: SessionRequestState;

  /**
   * Sign in operation request state using Google.
   */
  readonly signInWithGoogle: SessionRequestState;

  /**
   * Sign up operation request state.
   */
  readonly signUp: SessionRequestState;

  /**
   * Verify password reset operation request state.
   */
  readonly verifyPasswordResetCode: SessionRequestState;

  /**
   * Verify email address operation request state.
   */
  readonly verifyEmailCode: SessionRequestState;

  /**
   * Sign out operation request state.
   */
  readonly signOut: SessionRequestState;

  /**
   * Send sign out message to extension request state.
   */
  readonly sendSignOutExtensionMessage: SessionRequestState;

  /**
   * Send authentication data message to extension request state.
   */
  readonly sendAuthDataExtensionMessage: SessionRequestState;

  /**
   * Update password request state.
   */
  readonly updatePassword: SessionRequestState;

  /**
   * The visibility of authentication change modals.
   */
  readonly modalVisibilityByName: {
    readonly successfulSignIn: boolean;
    readonly successfulSignOut: boolean;
  };
};

export type SessionVerifyEmailCodeError =
  | (SerializedError & {
      kind: "serializedError";
    })
  | {
      kind: "custom";
      code: "wrong-account";
    };

export function isSessionVerifyEmailCodeError(
  error: unknown,
): error is SessionVerifyEmailCodeError {
  return (
    typeof error === "object" &&
    error !== null &&
    "kind" in error &&
    ((error as { readonly kind: unknown }).kind === "serializedError" ||
      (error as { readonly kind: unknown }).kind === "custom")
  );
}

/**
 * Action creators for authentication state changes monitored using the Firebase
 * `onAuthStateChanged` observer.
 */
export const sessionChangeCurrent = {
  pending: createAction("session/changeCurrent/pending"),
  fulfilled: createAction(
    "session/changeCurrent/fulfilled",
    ({ user }: { readonly user: SessionUser | null }) => ({
      payload: user,
    }),
  ),
  rejected: createAction(
    "session/changeCurrent/rejected",
    ({ error }: { readonly error: unknown }) => ({
      error: miniSerializeError(error),
      payload: {},
    }),
  ),
};

/**
 * Checks the availability of the provided email address.
 *
 * Returns `true` if the email address is available.
 *
 * Returns no result if a response is already in flight.
 */
export const sessionCheckEmailAvailability = createAsyncThunk<
  boolean | void,
  {
    readonly email: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/checkEmailAvailability",
  async ({ email: emailUnnormalized }, { extra, getState, requestId }) => {
    const { checkEmailAvailability: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const email = emailUnnormalized.trim().toLowerCase();
    const cachedResult =
      getState().session.checkEmailAvailability.byEmail[email];
    if (cachedResult !== undefined) return cachedResult;
    const response = await fetchSignInMethodsForEmail(auth, email);
    return response.length === 0;
  },
);

/**
 * Sends an email verification email to the currently authenticated user.
 *
 * This is a no-op if a request is already in flight.
 */
export const sessionSendEmailVerification = createAsyncThunk<
  void,
  void,
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/sendEmailVerification",
  async (_args, { extra, getState, requestId }) => {
    const { sendEmailVerification: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const { user } = extra.firebaseService;
    if (!user) return;
    await sendEmailVerification(user, {
      handleCodeInApp: true,
      url: extra.emailVerificationContinueUrl,
    });
  },
);

/**
 * Send a password reset email.
 *
 * This is a no-op if a request is already in flight.
 */
export const sessionSendPasswordResetEmail = createAsyncThunk<
  void,
  {
    readonly email: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/sendPasswordResetEmail",
  async ({ email }, { extra, getState, requestId }) => {
    const { sendPasswordResetEmail: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const actionCodeSettings = {
      url: extra.passwordResetEmailContinueUrl,
    };
    await sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(function() {
        console.log('Email sent successfully');
      })
      .catch(function(error) {
        console.log('Error', error);
      });
  },
);

/**
 * Sign the user in using the provided email and password.
 *
 * Returns no result if a request is already in flight.
 */
export const sessionSignIn = createAsyncThunk<
  SessionUser | void,
  {
    readonly email: string;
    readonly password: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/signIn",
  async ({ email, password }, { extra, getState, requestId }) => {
    const { signIn: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    extra.firebaseService.user = userCredential.user;
    return createSessionUserFromFirebaseUser(userCredential.user);
  },
);

/**
 * Sign the user in using Facebook.
 *
 * Returns no result if a request is already in flight.
 */
export const sessionSignInWithFacebook = createAsyncThunk<
  SessionUser | void,
  void,
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/signInWithFacebook",
  async (_args, { extra, getState, requestId }) => {
    const { signInWithFacebook: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const userCredential = await signInWithPopup(
      auth,
      extra.facebookAuthProvider,
    );
    extra.firebaseService.user = userCredential.user;
    return createSessionUserFromFirebaseUser(userCredential.user);
  },
);

/**
 * Sign the user in using Google.
 *
 * Returns no result if a request is already in flight.
 */
export const sessionSignInWithGoogle = createAsyncThunk<
  SessionUser | void,
  void,
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>("session/signInWithGoogle", async (_args, { extra, getState, requestId }) => {
  const { signInWithGoogle: request } = getState().session;
  if (request.status !== "loading" || request.currentRequestId !== requestId) {
    return;
  }

  const firebaseApp = extra.firebaseService.getApp();
  const auth = getAuth(firebaseApp);
  const userCredential = await signInWithPopup(auth, extra.googleAuthProvider);
  extra.firebaseService.user = userCredential.user;
  return createSessionUserFromFirebaseUser(userCredential.user);
});

/**
 * Sign the user up using an email address and password.
 *
 * Returns no result if a request is already in flight.
 */
export const sessionSignUp = createAsyncThunk<
  SessionUser | void,
  {
    readonly email: string;
    readonly password: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/signUp",
  async ({ email, password }, { extra, getState, requestId }) => {
    const { signUp: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    extra.firebaseService.user = userCredential.user;
    return createSessionUserFromFirebaseUser(userCredential.user);
  },
);

/**
 * Verifies a password reset code a user has received by email and sets their
 * password.
 *
 * This is a no-op if a request is already in flight.
 *
 * Returns the email address if successful.
 */
export const sessionVerifyPasswordResetCode = createAsyncThunk<
  string | void,
  {
    readonly code: string;
    readonly password: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/verifyPasswordResetCode",
  async ({ code, password }, { extra, getState, requestId }) => {
    const { verifyPasswordResetCode: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const firebaseApp = extra.firebaseService.getApp();
    const auth = getAuth(firebaseApp);
    const email = await verifyPasswordResetCode(auth, code);
    if (!email) return;
    await confirmPasswordReset(auth, code, password);
    return email;
  },
);

/**
 * Verifies the code sent by email to verify a user's email address.
 *
 * Returns an error with code `wrong-account` if the verification email was sent
 * to an email address which differs from the email of the currently
 * authenticated account.
 *
 * This is a no-op if a request is already in flight.
 */
export const sessionVerifyEmailCode = createAsyncThunk<
  void,
  {
    readonly oobCode: string;
  },
  {
    readonly extra: StoreServices;
    readonly rejectValue:
      | (SerializedError & {
          kind: "serializedError";
        })
      | {
          kind: "custom";
          code: "wrong-account";
        };
    readonly state: RootState;
  }
>(
  "session/verifyEmailCode",
  async ({ oobCode }, { extra, getState, rejectWithValue, requestId }) => {
    const { verifyEmailCode: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    try {
      const firebaseApp = extra.firebaseService.getApp();
      const auth = getAuth(firebaseApp);
      const metadata = await checkActionCode(auth, oobCode);
      const { current } = getState().session;
      if (
        !metadata.data.email ||
        current.status !== "success" ||
        !current.user ||
        metadata.data.email !== current.user.email
      ) {
        return rejectWithValue({
          code: "wrong-account",
          kind: "custom",
        });
      }

      await applyActionCode(auth, oobCode);
      return;
    } catch (error) {
      return rejectWithValue({
        ...miniSerializeError(error),
        kind: "serializedError",
      });
    }
  },
);

/**
 * Signs the user out.
 *
 * This is a no-op if a request is already in flight.
 */
export const sessionSignOut = createAsyncThunk<
  void,
  {
    /**
     * The `pathname` field from `useLocation`'s return value.
     */
    readonly locationPathname: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>("session/signOut", async (_args, { extra, getState, requestId }) => {
  const { signOut: request } = getState().session;
  if (request.status !== "loading" || request.currentRequestId !== requestId) {
    return;
  }

  const firebaseApp = extra.firebaseService.getApp();
  const auth = getAuth(firebaseApp);
  signOut(auth);
  extra.firebaseService.user = null;
});

/**
 * Updates the user's password.
 *
 * This is a no-op if a request is already in flight or the user isn't
 * authenticated.
 */
export const sessionUpdatePassword = createAsyncThunk<
  SessionUser | void,
  {
    readonly newPassword: string;
    readonly oldPassword: string;
  },
  {
    readonly extra: StoreServices;
    readonly state: RootState;
  }
>(
  "session/updatePassword",
  async ({ newPassword, oldPassword }, { extra, getState, requestId }) => {
    const { updatePassword: request } = getState().session;
    if (
      request.status !== "loading" ||
      request.currentRequestId !== requestId
    ) {
      return;
    }

    const { user } = extra.firebaseService;
    if (!user || !user.email) return;
    const emailAuthCredential = EmailAuthProvider.credential(
      user.email,
      oldPassword,
    );
    const userCredential: UserCredential = await reauthenticateWithCredential(
      user,
      emailAuthCredential,
    );
    await updatePassword(user, newPassword);
    return createSessionUserFromFirebaseUser(userCredential.user);
  },
);

const initialRequestState: Extract<SessionRequestState, { status: "initial" }> =
  {
    status: "initial",
  };

const initialState: SessionState = {
  current: initialRequestState,
  checkEmailAvailability: {
    byEmail: {},
    status: "initial",
  },
  sendEmailVerification: initialRequestState,
  sendPasswordResetEmail: initialRequestState,
  signIn: initialRequestState,
  signInWithFacebook: initialRequestState,
  signInWithGoogle: initialRequestState,
  signUp: initialRequestState,
  verifyPasswordResetCode: initialRequestState,
  verifyEmailCode: initialRequestState,
  signOut: initialRequestState,
  sendSignOutExtensionMessage: initialRequestState,
  sendAuthDataExtensionMessage: initialRequestState,
  updatePassword: initialRequestState,
  modalVisibilityByName: {
    successfulSignIn: false,
    successfulSignOut: false,
  },
};

const sessionSlice = createSlice({
  extraReducers: (builder) => {
    // Handle authentication state changes from the session middleware. The
    // session middleware monitors for authentication state changes using the
    // Firebase "onAuthStateChanged" observable.
    addRequestCaseReducers({
      actionCreators: sessionChangeCurrent,
      builder,
      caseReducers: {
        pending: (state) => {
          state.current = {
            status: "loading",
          };
        },
        fulfilled: (state, action) => {
          state.current = {
            status: "success",
            user: action.payload,
          };
        },
        rejected: (state, action) => {
          state.current = {
            error: action.error,
            status: "error",
          };
        },
      },
      name: "current",
    });

    // Handle email availability check requests, while only allowing a single
    // one at a time.
    // Cache email addresses which have already been checked.
    addRequestCaseReducers({
      actionCreators: sessionCheckEmailAvailability,
      builder,
      caseReducers: {
        pending: (state, action) => {
          state.checkEmailAvailability = {
            byEmail: state.checkEmailAvailability.byEmail,
            currentRequestId: action.meta.requestId,
            status: "loading",
          };
        },
        fulfilled: (state, action) => {
          const email = action.meta.arg.email.trim().toLowerCase();
          const isAvailable = action.payload;
          if (typeof isAvailable !== "boolean") return;
          state.checkEmailAvailability = {
            byEmail: {
              ...state.checkEmailAvailability.byEmail,
              [email]: isAvailable,
            },
            status: "success",
            email,
            isAvailable,
          };
        },
        rejected: (state, action) => {
          state.checkEmailAvailability = {
            byEmail: state.checkEmailAvailability.byEmail,
            error: action.error,
            status: "error",
          };
        },
      },
      name: "checkEmailAvailability",
      singleRequest: true,
    });

    // Handle email verification requests, while only allowing a single one at a
    // time.
    addRequestCaseReducers({
      actionCreators: sessionSendEmailVerification,
      builder,
      name: "sendEmailVerification",
      singleRequest: true,
    });

    // Handle the sending of a password reset email, only allowing a single
    // request at a time.
    addRequestCaseReducers({
      actionCreators: sessionSendPasswordResetEmail,
      builder,
      name: "sendPasswordResetEmail",
      singleRequest: true,
    });

    // Handle sign in requests, while only allowing a single one at a time.
    addRequestCaseReducers({
      actionCreators: sessionSignIn,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          if (typeof action.payload !== "object") return;
          state.current = {
            status: "success",
            user: action.payload,
          };
          state.signIn = {
            status: "success",
          };
          state.modalVisibilityByName.successfulSignIn = true;
        },
      },
      name: "signIn",
      singleRequest: true,
    });

    // Handle sign in with Facebook requests, while only allowing a single one
    // at a time.
    addRequestCaseReducers({
      actionCreators: sessionSignInWithFacebook,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          if (typeof action.payload !== "object") return;
          state.current = {
            status: "success",
            user: action.payload,
          };
          state.signInWithFacebook = {
            status: "success",
          };
          state.modalVisibilityByName.successfulSignIn = true;
        },
      },
      name: "signInWithFacebook",
      singleRequest: true,
    });

    // Handle sign in with Google requests, while only allowing a single one
    // at a time.
    addRequestCaseReducers({
      actionCreators: sessionSignInWithGoogle,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          if (typeof action.payload !== "object") return;
          state.current = {
            status: "success",
            user: action.payload,
          };
          state.signInWithGoogle = {
            status: "success",
          };
          state.modalVisibilityByName.successfulSignIn = true;
        },
      },
      name: "signInWithGoogle",
      singleRequest: true,
    });

    // Handle sign up requests, while only allowing a single one at a time.
    addRequestCaseReducers({
      actionCreators: sessionSignUp,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          if (typeof action.payload !== "object") return;
          state.current = {
            status: "success",
            user: action.payload,
          };
          state.signUp = {
            status: "success",
          };
        },
      },
      name: "signUp",
      singleRequest: true,
    });

    // Handle password reset verification requests, only allowing a single at a
    // time.
    addRequestCaseReducers({
      actionCreators: sessionVerifyPasswordResetCode,
      builder,
      name: "verifyPasswordResetCode",
      singleRequest: true,
    });

    // Handle email verification requests, only allowing a single at a time.
    addRequestCaseReducers({
      actionCreators: sessionVerifyEmailCode,
      builder,
      name: "verifyEmailCode",
      singleRequest: true,
    });

    // Handle sign out requests, only allowing a single at a time.
    addRequestCaseReducers({
      actionCreators: sessionSignOut,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          state.current = {
            status: "success",
            user: null,
          };
          state.signOut = {
            status: "success",
          };
          if (action.meta.arg.locationPathname.includes("update-profile")) {
            state.modalVisibilityByName.successfulSignOut = true;
          }
        },
      },
      name: "signOut",
      singleRequest: true,
    });

    addRequestCaseReducers({
      actionCreators: sessionUpdatePassword,
      builder,
      caseReducers: {
        fulfilled: (state, action) => {
          if (typeof action.payload !== "object") return;
          state.current = {
            status: "success",
            user: action.payload,
          };
          state.updatePassword = {
            status: "success",
          };
        },
      },
      name: "updatePassword",
      singleRequest: true,
    });
  },
  // Use a type cast here to prevent type narrowing.
  initialState: initialState as SessionState,
  name: "session",
  reducers: {
    dismissModal: (
      state,
      action: PayloadAction<keyof SessionState["modalVisibilityByName"]>,
    ) => {
      state.modalVisibilityByName[action.payload] = false;
    },
  },
});

export const {
  actions: { dismissModal: sessionDismissModal },
  name: sessionReducerPath,
  reducer: sessionReducer,
} = sessionSlice;

/**
 * A state selector which returns the loading status and state of the current
 * session.
 */
export const sessionSelectCurrent = (state: RootState) => state.session.current;

/**
 * A state selector which returns whether or not the user is currently
 * authenticated.
 */
export const sessionSelectIsAuthenticated = (state: RootState) => {
  const current = sessionSelectCurrent(state);
  return current.status === "success" && !!current.user;
};

/**
 * A state selector which returns the current user, if authenticated, or `null`.
 */
export const sessionSelectUser = (state: RootState) => {
  const current = sessionSelectCurrent(state);
  return current.status === "success" ? current.user : null;
};
