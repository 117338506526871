import * as z from "zod";

/**
 * The progress of a user through a lesson.
 */
export type CurriculumApiDtoLessonProgress = z.infer<
  typeof curriculumApiDtoLessonProgressSchema
>;

/**
 * The validation schema for `CurriculumApiDtoLessonProgress`.
 *
 * @see {CurriculumApiDtoLessonProgress}
 */
export const curriculumApiDtoLessonProgressSchema = z.object({
  current_page: z.number().int().optional().nullable(),
  current_section: z.number().int().optional().nullable(),
});
